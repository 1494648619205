import React,{Component} from 'react'
import './css/indications.css'
import M from 'materialize-css/dist/js/materialize.min.js'
class FirstIndications extends Component{
    componentDidMount() {
        M.AutoInit();
    }
    // Proceed to next step
    continue = (event)=> {
        event.preventDefault();
        this.props.nextStep();
    }
    render(){
        return(
        <div className='container'>
            <div className='row'>
                <div className='card col s12'>
                    <h4 className='instructionsTitlePilot'><i>Apreciado(a) Educador(a)</i></h4>
                    <p className='instructionsubTitlePilot'>Cordial saludo,</p>
                    <p className='instructionParagraphPilot'>
                            Usted ha sido seleccionado/a por el Ministerio de Educación Nacional para participar
                            en esta encuesta orientada a conocer la percepción sobre el Bienestar Laboral de los 
                            Docentes y Directivos Docentes de Preescolar, Básica y Media del sector educativo público. 
                            <br></br><br></br>
                            Su participación y apoyo son cruciales, toda vez que sus valiosas apreciaciones contribuyen 
                            a la orientación de la Política de Bienestar Laboral Docente y Desarrollo Profesoral.   
                            <br></br><br></br>
                            Por favor continúe con el diligenciamiento de la encuesta. 
                    </p>
                    <div className='card-action btnContainerPilot'>
                        <button 
                            type="submit" 
                            className="modal-close btn-flat waves-effect waves-light bg-btnPilot"
                            onClick={this.continue}>
                                INICIAR ENCUESTA
                        </button>
                    </div>
                </div>
            </div>
        </div>
        )
    }
}

export default FirstIndications