import React,{Component} from 'react'
import M from 'materialize-css/dist/js/materialize.min.js'
import './css/secondSection.css'
let secondQuestions = [
    '1. Durante la semana, el espacio físico donde desarrollo mi labor permanece limpio',
    '2. En general, las aulas, los equipos de cómputo, escritorios, tableros, libros, son',
    '3. En general, las condiciones de infraestructura escolar (servicios de agua, electricidad; espacios: deportivo, baños, oficinas, patio, salones; paredes, techos, pisos; la iluminación y ventilación de las aulas) son',
    '4. Considero que los elementos que conforman las dotaciones escolares son suficientes para desempeñar mi quehacer diario ',
    '5. Durante la jornada escolar cuento con herramientas de calidad que facilitan el uso, acceso y apropiación de las Tecnologías de la Información y las Comunicaciones',
    '6. Siento que mi lugar de trabajo es seguro ',
    '7. Percibo que existe una cultura de seguridad laboral',
    '8. Me practico los exámenes de salud ocupacional de manera periódica ',
    '9. Normalmente, durante el día permanezco en el establecimiento educativo',
    '10. Percibo que la zona donde se encuentra el establecimiento educativo es segura',
    '11. En mi lugar de trabajo cuento con la información oportuna y los recursos suficientes para prevenir riesgos laborales',
    '12. El ambiente laboral donde ejerzo mi rol profesional es estimulante y satisfactorio',
    '13. Durante el último año, he realizado cursos o programas de formación profesional por cuenta propia',
    '14. Pienso que los valores organizacionales del establecimiento educativo donde trabajo son acordes con mis valores profesionales y personales',
    '15. El Establecimiento Educativo adelanta un proceso de adaptación y socialización para los empleados nuevos',
    '16. Existen planes y programas de salud en los cuales se monitorea nuestro bienestar',
]
let secondNameInput = [
    'question1',
    'question2',
    'question3',
    'question4',
    'question5',
    'question6',
    'question7',
    'question8',
    'question9',
    'question10',
    'question11',
    'question12',
    'question13',
    'question14',
    'question15',
    'question16',
]
class SecondSection extends Component{
    componentDidMount() {
        M.AutoInit();
    }
    // Proceed to next step
    continue = (event)=> {
        event.preventDefault();
        this.props.createFormSection2();
    }
    // Validate Message
    validateForm2 = ()=>{
        if(!this.props.question1){
            alert('Selecciona una opcion para la pregunta numero 1')
        }  
        else if(!this.props.question2){
            alert('Selecciona una opcion para la pregunta numero 2')
        }
        else if(!this.props.question3){
            alert('Selecciona una opcion para la pregunta numero 3')
        }
        else if(!this.props.question4){
            alert('Selecciona una opcion para la pregunta numero 4')
        }
        else if(!this.props.question5){
            alert('Selecciona una opcion para la pregunta numero 5')
        }
        else if(!this.props.question6){
            alert('Selecciona una opcion para la pregunta numero 6')
        }
        else if(!this.props.question7){
            alert('Selecciona una opcion para la pregunta numero 7')
        }
        else if(!this.props.question8){
            alert('Selecciona una opcion para la pregunta numero 8')
        }
        else if(!this.props.question9){
            alert('Selecciona una opcion para la pregunta numero 9')
        }
        else if(!this.props.question10){
            alert('Selecciona una opcion para la pregunta numero 10')
        }
        else if(!this.props.question11){
            alert('Selecciona una opcion para la pregunta numero 11')
        }
        else if(!this.props.question12){
            alert('Selecciona una opcion para la pregunta numero 12')
        }
        else if(!this.props.question13){
            alert('Selecciona una opcion para la pregunta numero 13')
        }
        else if(!this.props.question14){
            alert('Selecciona una opcion para la pregunta numero 14')
        }
        else if(!this.props.question15){
            alert('Selecciona una opcion para la pregunta numero 15')
        }
        else if(!this.props.question16){
            alert('Selecciona una opcion para la pregunta numero 16')
        }
    }
render(){
    return (
        <div className='container'>
            <div className='row'>
                <div className='col s12'>
                    <div className='card'>
                            <div className='secondSectionBox col s12'>
                                <span className=' secondSectionBoxTitle'>II Sección: Bienestar Ambiental</span>
                                <span className='secondSectionBoxSubTitle col s12'>Aborda la percepción y valoración 
                                sobre condiciones externas que posiblemente influyen en su desempeño como docente y/o 
                                Directivo Docente, tales como:  infraestructura; dotaciones escolares y de tecnología; 
                                disponibilidad de recursos de enseñanza/aprendizaje; clima laboral (valores) e 
                                implementación de prácticas profesionales; seguridad escolar y salud física-ocupacional.</span>
                            </div>
                        <div className='card-content'>
                        <div className='input-field col s12'>
                            <h6 className='fGeneralDetailsSubTitle'>A continuación, encontrará una serie de preguntas relacionadas con su experiencia en el Establecimiento Educativo para el cual trabaja. 
                            Por favor seleccione la opción de respuesta que considere más cercana a su modo de pensar. Entre 
                            las opciones de respuesta, elija solamente una. Recuerde que no existen respuestas correctas o 
                            incorrectas, así que responda con tranquilidad y honestidad. </h6>
                        </div>
                            <form onSubmit={this.continue}>
                                <div className='row'>
                                    {/* Section 2.1 */}
                                    <div className='input-field col s12 separator'>
                                        <select className="validate" required name={secondNameInput[0]} onChange={this.props.handleChange}>
                                            <option value="">Seleccione una opción</option>
                                            <option value="5">Siempre </option>
                                            <option value="4">La mayoría de las veces sí</option>
                                            <option value="3">Algunas veces sí, algunas veces no</option>
                                            <option value="2">La mayoría de las veces no</option>
                                            <option value="1">Nunca	</option>
                                        </select>
                                        <label htmlFor='question1'>{secondQuestions[0]}</label>
                                    </div>
                                    <div className='input-field col s12'>
                                        <select className="validate" required name={secondNameInput[1]} onChange={this.props.handleChange}>
                                            <option value="">Seleccione una opción</option>
                                            <option value="5">Muy Buenos</option>
                                            <option value="4">Buenos</option>
                                            <option value="3">Regulares</option>
                                            <option value="2">Malos</option>
                                            <option value="1">Muy Malos	</option>
                                            <option value="0">No aplica</option>
                                        </select>
                                        <label htmlFor='question2'>{secondQuestions[1]}</label>
                                    </div>
                                    <div className='input-field col s12 separator'>
                                        <select className="validate" required name={secondNameInput[2]} onChange={this.props.handleChange}>
                                            <option value="">Seleccione una opción</option>
                                            <option value="5">Muy Buenas</option>
                                            <option value="4">Buenas</option>
                                            <option value="3">Regulares</option>
                                            <option value="2">Malas</option>
                                            <option value="1">Muy Malas</option>
                                        </select>
                                        <label className='separatorQuestion' htmlFor='question3'>{secondQuestions[2]}</label>
                                    </div>
                                    <div className='input-field col s12'>
                                        <select className="validate" required name={secondNameInput[3]} onChange={this.props.handleChange}>
                                            <option value="">Seleccione una opción</option>
                                            <option value="5">Siempre </option>
                                            <option value="4">La mayoría de las veces sí</option>
                                            <option value="3">Algunas veces sí, algunas veces no</option>
                                            <option value="2">La mayoría de las veces no</option>
                                            <option value="1">Nunca	</option>
                                        </select>
                                        <label>{secondQuestions[3]}</label>
                                    </div>
                                    <div className='input-field col s12 separator'>
                                        <select className="validate" required name={secondNameInput[4]} onChange={this.props.handleChange}>
                                            <option value="">Seleccione una opción</option>
                                            <option value="1">Menos de 1 hora</option>
                                            <option value="2">Entre 1 y 2 horas</option>
                                            <option value="3">Entre 2 y 3 horas</option>
                                            <option value="4">Más de 3 horas</option>
                                            <option value="0">No cuento con esas herramientas</option>
                                        </select>
                                        <label className='separatorQuestion'>{secondQuestions[4]}</label>
                                    </div>
                                    <div className='input-field col s12 separator'>
                                        <select className="validate" required name={secondNameInput[5]} onChange={this.props.handleChange}>
                                            <option value="">Seleccione una opción</option>
                                            <option value="5">Siempre </option>
                                            <option value="4">La mayoría de las veces sí</option>
                                            <option value="3">Algunas veces sí, algunas veces no</option>
                                            <option value="2">La mayoría de las veces no</option>
                                            <option value="1">Nunca	</option>
                                        </select>
                                        <label>{secondQuestions[5]}</label>
                                    </div>
                                    <div className='input-field col s12 separator'>
                                        <select className="validate" required name={secondNameInput[6]} onChange={this.props.handleChange}>
                                            <option value="">Seleccione una opción</option>
                                            <option value="5">Siempre </option>
                                            <option value="4">La mayoría de las veces sí</option>
                                            <option value="3">Algunas veces sí, algunas veces no</option>
                                            <option value="2">La mayoría de las veces no</option>
                                            <option value="1">Nunca	</option>
                                        </select>
                                        <label>{secondQuestions[6]}</label>
                                    </div>
                                    <div className='input-field col s12 separator'>
                                        <select className="validate" required name={secondNameInput[7]} onChange={this.props.handleChange}>
                                            <option value="">Seleccione una opción</option>
                                            <option value="5">Siempre </option>
                                            <option value="4">La mayoría de las veces sí</option>
                                            <option value="3">Algunas veces sí, algunas veces no</option>
                                            <option value="2">La mayoría de las veces no</option>
                                            <option value="1">Nunca	</option>
                                        </select>
                                        <label>{secondQuestions[7]}</label>
                                    </div>
                                    <div className='input-field col s12 separator'>
                                        <select className="validate" required name={secondNameInput[8]} onChange={this.props.handleChange}>
                                            <option value="">Seleccione una opción</option>
                                            <option value="1">Menos de 6 horas </option>
                                            <option value="2">Entre 6 y 8 horas </option>
                                            <option value="3">Más de 8 horas</option>
                                        </select>
                                        <label>{secondQuestions[8]}</label>
                                    </div>
                                    <div className='input-field col s12 separator'>
                                        <select className="validate" required name={secondNameInput[9]} onChange={this.props.handleChange}>
                                            <option value="">Seleccione una opción</option>
                                            <option value="5">Siempre </option>
                                            <option value="4">La mayoría de las veces sí</option>
                                            <option value="3">Algunas veces sí, algunas veces no</option>
                                            <option value="2">La mayoría de las veces no</option>
                                            <option value="1">Nunca	</option>
                                        </select>
                                        <label>{secondQuestions[9]}</label>
                                    </div>
                                    <div className='input-field col s12 separator'>
                                        <select className="validate" required name={secondNameInput[10]} onChange={this.props.handleChange}>
                                            <option value="">Seleccione una opción</option>
                                            <option value="5">Siempre </option>
                                            <option value="4">La mayoría de las veces sí</option>
                                            <option value="3">Algunas veces sí, algunas veces no</option>
                                            <option value="2">La mayoría de las veces no</option>
                                            <option value="1">Nunca	</option>
                                        </select>
                                        <label>{secondQuestions[10]}</label>
                                    </div>
                                    <div className='input-field col s12 separator'>
                                        <select className="validate" required name={secondNameInput[11]} onChange={this.props.handleChange}>
                                            <option value="">Seleccione una opción</option>
                                            <option value="5">Siempre </option>
                                            <option value="4">La mayoría de las veces sí</option>
                                            <option value="3">Algunas veces sí, algunas veces no</option>
                                            <option value="2">La mayoría de las veces no</option>
                                            <option value="1">Nunca	</option>
                                        </select>
                                        <label className='topInput'>{secondQuestions[11]}</label>
                                    </div>
                                    <div className='input-field col s12 separator'>
                                        <select className="validate" required name={secondNameInput[12]} onChange={this.props.handleChange}>
                                            <option value="">Seleccione una opción</option>
                                            {/* <option value="5">Siempre </option>
                                            <option value="4">La mayoría de las veces sí</option>
                                            <option value="3">Algunas veces sí, algunas veces no</option>
                                            <option value="2">La mayoría de las veces no</option>
                                            <option value="1">Nunca	</option> */}
                                            <option value="1">SI	</option>
                                            <option value="0">NO	</option>
                                        </select>
                                        <label className='topInput'>{secondQuestions[12]}</label>
                                    </div>
                                    <div className='input-field col s12 separator'>
                                        <select className="validate" required name={secondNameInput[13]} onChange={this.props.handleChange}>
                                            <option value="">Seleccione una opción</option>
                                            <option value="5">Siempre </option>
                                            <option value="4">La mayoría de las veces sí</option>
                                            <option value="3">Algunas veces sí, algunas veces no</option>
                                            <option value="2">La mayoría de las veces no</option>
                                            <option value="1">Nunca	</option>
                                        </select>
                                        <label className='separatorQuestion'>{secondQuestions[13]}</label>
                                    </div>
                                    <div className='input-field col s12 separator'>
                                        <select className="validate" required name={secondNameInput[14]} onChange={this.props.handleChange}>
                                            <option value="">Seleccione una opción</option>
                                            <option value="5">Siempre </option>
                                            <option value="4">La mayoría de las veces sí</option>
                                            <option value="3">Algunas veces sí, algunas veces no</option>
                                            <option value="2">La mayoría de las veces no</option>
                                            <option value="1">Nunca	</option>
                                        </select>
                                        <label className='topInput'>{secondQuestions[14]}</label>
                                    </div>
                                    <div className='input-field col s12 separator'>
                                        <select className="validate" required name={secondNameInput[15]} onChange={this.props.handleChange}>
                                            <option value="">Seleccione una opción</option>
                                            <option value="5">Siempre </option>
                                            <option value="4">La mayoría de las veces sí</option>
                                            <option value="3">Algunas veces sí, algunas veces no</option>
                                            <option value="2">La mayoría de las veces no</option>
                                            <option value="1">Nunca	</option>
                                        </select>
                                        <label className='topInput'>{secondQuestions[15]}</label>
                                    </div>
                                    {/* Section 2.1 */}
                                </div>
                                <button onClick={this.validateForm2} className="btn waves-effect waves-light bg-btn" type="submit" name="action">
                                    Continuar
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        )
    }
}
export default SecondSection