import React,{Component} from 'react'
import M from 'materialize-css/dist/js/materialize.min.js'

import './css/thirdSection.css'
let thirdQuestions = [
    '17. Pienso que mi desarrollo laboral es acorde con mi proyecto profesional y vital',
    '18. En el desarrollo de mi trabajo puedo ser creativo y tomar decisiones',
    '19. Siento que hay un equilibrio entre el tiempo de mi jornada laboral y mi tiempo libre',
    '20. En mi ejercicio profesional tengo metas claras y son congruentes con mis acciones laborales',
    '21. Recibo retroalimentación de mis labores docentes y/o de directivo docente',
    '22. Recibo apoyo y motivación en el desarrollo de mis capacidades docentes y/o de directivo docente',
    '23. Siento que disfruto mi quehacer y rol laboral',
    '24. Recibo apoyo socioemocional y/o capacitación en desarrollo socioemocional',
    '25. Conforme a su experiencia y opinión como Docente y/o Directivo Docente, de los siguientes aspectos indique qué tanto éstos motivan su práctica profesional',
    '25-1. El aprendizaje de los estudiantes ',
    '25-2. Introducir y desarrollar nuevas metodologías de enseñanza',
    '25-3. El intercambio de experiencias pedagógicas exitosas entre colegas',
    '25-4. Asumir retos y diferentes responsabilidades',
    '25-5. Tomar el liderazgo pedagógico para orientar el trabajo de otros',
    '25-6. El reconocimiento de la comunidad académica',
    '25-7. El sentido de pertenencia con el sistema educativo ',
    '25-8. Aportar a la construcción del proyecto de vida de los estudiantes',
    '25-9. La convicción de que ser docente da sentido a mi vida',
    '25-10. El logro de resultados positivos',
    '25-11. La capacidad y vocación de servicio hacia los demás',
    '25-12. La estabilidad laboral y el goce de una vejez digna',
    '25-13. Aportar al cambio social desde la educación',
    '25-14. Suplir las necesidades materiales básicas',
    '25-15. Profundizar en conocimientos y oportunidades',
    '25-16. El aprendizaje de competencias propias de la carrera docente',
    '25-17. El desarrollo autónomo y responsable',
    '25-18. El equilibrio entre la capacidad de enseñanza y aprendizajes',
    '25-19. El crecimiento personal y el logro de aspiraciones profesionales',
    '25-20. Aportar al desarrollo local desde la educación'
]
class ThirdSection extends Component{
    componentDidMount() {
        M.AutoInit();
    }
    // Proceed to next step
    continue = (event)=> {
        event.preventDefault();
        this.props.createFormSection3();
    }
    // Validate Message
    validateForm3 = ()=>{
        if(!this.props.question17){
            alert('Selecciona una opcion para la pregunta numero 17')
        }
        else if(!this.props.question18){
            alert('Selecciona una opcion para la pregunta numero 18')
        }
        else if(!this.props.question19){
            alert('Selecciona una opcion para la pregunta numero 19')
        }
        else if(!this.props.question20){
            alert('Selecciona una opcion para la pregunta numero 20')
        }
        else if(!this.props.question21){
            alert('Selecciona una opcion para la pregunta numero 21')
        }
        else if(!this.props.question22){
            alert('Selecciona una opcion para la pregunta numero 22')
        }
        else if(!this.props.question23){
            alert('Selecciona una opcion para la pregunta numero 23')
        }
        else if(!this.props.question24){
            alert('Selecciona una opcion para la pregunta numero 24')
        }
        else if(!this.props.question25_1){
            alert('Selecciona una opcion para la pregunta numero 25-1')
        }
        else if(!this.props.question25_2){
            alert('Selecciona una opcion para la pregunta numero 25-2')
        }
        else if(!this.props.question25_3){
            alert('Selecciona una opcion para la pregunta numero 25-3')
        }
        else if(!this.props.question25_4){
            alert('Selecciona una opcion para la pregunta numero 25-4')
        }
        else if(!this.props.question25_5){
            alert('Selecciona una opcion para la pregunta numero 25-5')
        }
        else if(!this.props.question25_6){
            alert('Selecciona una opcion para la pregunta numero 25-6')
        }
        else if(!this.props.question25_7){
            alert('Selecciona una opcion para la pregunta numero 25-7')
        }
        else if(!this.props.question25_8){
            alert('Selecciona una opcion para la pregunta numero 25-8')
        }
        else if(!this.props.question25_9){
            alert('Selecciona una opcion para la pregunta numero 25-9')
        }
        else if(!this.props.question25_10){
            alert('Selecciona una opcion para la pregunta numero 25-10')
        }
        else if(!this.props.question25_11){
            alert('Selecciona una opcion para la pregunta numero 25-11')
        }
        else if(!this.props.question25_12){
            alert('Selecciona una opcion para la pregunta numero 25-12')
        }
        else if(!this.props.question25_13){
            alert('Selecciona una opcion para la pregunta numero 25-13')
        }
        else if(!this.props.question25_14){
            alert('Selecciona una opcion para la pregunta numero 25-14')
        }
        else if(!this.props.question25_15){
            alert('Selecciona una opcion para la pregunta numero 25-15')
        }
        else if(!this.props.question25_16){
            alert('Selecciona una opcion para la pregunta numero 25-16')
        }
        else if(!this.props.question25_17){
            alert('Selecciona una opcion para la pregunta numero 25-17')
        }
        else if(!this.props.question25_18){
            alert('Selecciona una opcion para la pregunta numero 25-18')
        }
        else if(!this.props.question25_19){
            alert('Selecciona una opcion para la pregunta numero 25-19')
        }
        else if(!this.props.question25_20){
            alert('Selecciona una opcion para la pregunta numero 25-20')
        }
        
    }    
render(){
    return (
        <div className='container'>
            <div className='row'>
                <div className='col s12'>
                    <div className='card'>
                        <div className='col s12 thirdSectionBox'>
                            <span className='thirdSectionBoxTitle'>III Sección: Bienestar Subjetivo</span>
                            <span className='thirdSectionBoxSubTitle col s12'> Percepción frente a la identificación de capacidades desde el <i>saber ser </i> y el <i>saber hacer.</i> </span>
                        </div>
                        <div className='card-content'>
                            <div className='input-field col s12'>
                                <h6 className='fGeneralDetailsSubTitle'>A continuación, encontrará una serie 
                                de afirmaciones. Por favor seleccione la opción de respuesta 
                                que considere más cercana a su modo de pensar, sentir  y actuar. Entre las 
                                opciones de respuesta, elija solo una. Recuerde que no existen 
                                respuestas correctas o incorrectas, así que responda con tranquilidad y honestidad.</h6>
                            </div>
                            <form onSubmit={this.continue}>
                                <div className='row'>
                                    {/* Section 3.1 */}
                                    <div className='input-field col s12 separator'>
                                        <select required name='question17' onChange={this.props.handleChange}>
                                            <option value="">Seleccione una opción</option>
                                            <option value="5">Siempre </option>
                                            <option value="4">La mayoría de las veces sí</option>
                                            <option value="3">Algunas veces sí, algunas veces no </option>
                                            <option value="2">La mayoría de las veces no</option>
                                            <option value="1">Nunca	</option>
                                        </select>
                                        <label>{thirdQuestions[0]}</label>
                                    </div>
                                    <div className='input-field col s12 separator'>
                                        <select required name='question18' onChange={this.props.handleChange}>
                                            <option value="">Seleccione una opción</option>
                                            <option value="5">Siempre </option>
                                            <option value="4">La mayoría de las veces sí</option>
                                            <option value="3">Algunas veces sí, algunas veces no </option>
                                            <option value="2">La mayoría de las veces no</option>
                                            <option value="1">Nunca	</option>
                                        </select>
                                        <label>{thirdQuestions[1]}</label>
                                    </div>
                                    <div className='input-field col s12 separator'>
                                        <select required name='question19' onChange={this.props.handleChange}>
                                            <option value="">Seleccione una opción</option>
                                            <option value="5">Siempre </option>
                                            <option value="4">La mayoría de las veces sí</option>
                                            <option value="3">Algunas veces sí, algunas veces no </option>
                                            <option value="2">La mayoría de las veces no</option>
                                            <option value="1">Nunca	</option>
                                        </select>
                                        <label>{thirdQuestions[2]}</label>
                                    </div>
                                    <div className='input-field col s12 separator'>
                                        <select required name='question20' onChange={this.props.handleChange}>
                                            <option value="">Seleccione una opción</option>
                                            <option value="5">Siempre </option>
                                            <option value="4">La mayoría de las veces sí</option>
                                            <option value="3">Algunas veces sí, algunas veces no </option>
                                            <option value="2">La mayoría de las veces no</option>
                                            <option value="1">Nunca	</option>
                                        </select>
                                        <label>{thirdQuestions[3]}</label>
                                    </div>
                                    <div className='input-field col s12 separator'>
                                        <select required name='question21' onChange={this.props.handleChange}>
                                            <option value="">Seleccione una opción</option>
                                            <option value="5">Siempre </option>
                                            <option value="4">La mayoría de las veces sí</option>
                                            <option value="3">Algunas veces sí, algunas veces no </option>
                                            <option value="2">La mayoría de las veces no</option>
                                            <option value="1">Nunca	</option>
                                        </select>
                                        <label>{thirdQuestions[4]}</label>
                                    </div>
                                    <div className='input-field col s12 separator'>
                                        <select required name='question22' onChange={this.props.handleChange}>
                                            <option value="">Seleccione una opción</option>
                                            <option value="5">Siempre </option>
                                            <option value="4">La mayoría de las veces sí</option>
                                            <option value="3">Algunas veces sí, algunas veces no </option>
                                            <option value="2">La mayoría de las veces no</option>
                                            <option value="1">Nunca	</option>
                                        </select>
                                        <label>{thirdQuestions[5]}</label>
                                    </div>
                                    <div className='input-field col s12 separator'>
                                        <select required name='question23' onChange={this.props.handleChange}>
                                            <option value="">Seleccione una opción</option>
                                            <option value="5">Siempre </option>
                                            <option value="4">La mayoría de las veces sí</option>
                                            <option value="3">Algunas veces sí, algunas veces no </option>
                                            <option value="2">La mayoría de las veces no</option>
                                            <option value="1">Nunca	</option>
                                        </select>
                                        <label>{thirdQuestions[6]}</label>
                                    </div>
                                    <div className='input-field col s12 separator'>
                                        <select required name='question24' onChange={this.props.handleChange}>
                                            <option value="">Seleccione una opción</option>
                                            <option value="5">Siempre </option>
                                            <option value="4">La mayoría de las veces sí</option>
                                            <option value="3">Algunas veces sí, algunas veces no </option>
                                            <option value="2">La mayoría de las veces no</option>
                                            <option value="1">Nunca	</option>
                                        </select>
                                        <label>{thirdQuestions[7]}</label>
                                    </div>
                                    {/* Section 3.1 */}
                                    <span className='SubTitle col s12 separator'>Responda las siguientes 
                                    preguntas considerando la escala del 1 al 5 donde:<br></br>
                                    <span className='descRange'>5</span> Muy Motivante, 
                                    <span className='descRange'> 4</span> Algo Motivante,
                                    <span className='descRange'> 3</span> Indiferente,
                                    <span className='descRange'> 2</span> Poco Motivante y 
                                    <span className='descRange'> 1</span> Muy Desmotivante.</span>
                                    <span className='col s12 separator labelQuestion'>{thirdQuestions[8]}</span>
                                    {/* Section 3.2 */}
                                    <div className='input-field col s12 separator'>
                                        <p>
                                            <label className='boldQuestion'>{thirdQuestions[9]}</label>
                                        </p>
                                        <p className='col s12'>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input required value='5' name="question25_1" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>5. Muy Motivante</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='4' name="question25_1" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>4. Algo Motivante</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='3' name="question25_1" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>3. Indiferente</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='2' name="question25_1" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>2. Poco Motivante</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='1' name="question25_1" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>1. Muy Desmotivante</span>
                                            </label> 
                                        </p>
                                    </div>
                                    <div className='input-field col s12'>
                                        <p>
                                            <label>{thirdQuestions[10]}</label>
                                        </p>
                                        <p className='col s12'>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input required value='5' name="question25_2" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>5. Muy Motivante</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='4' name="question25_2" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>4. Algo Motivante</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='3' name="question25_2" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>3. Indiferente</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='2' name="question25_2" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>2. Poco Motivante</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='1' name="question25_2" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>1. Muy Desmotivante</span>
                                            </label> 
                                        </p>
                                    </div>
                                    <div className='input-field col s12'>
                                        <p>
                                            <label>{thirdQuestions[11]}</label>
                                        </p>
                                        <p className='col s12'>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input required value='5' name="question25_3" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>5. Muy Motivante</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='4' name="question25_3" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>4. Algo Motivante</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='3' name="question25_3" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>3. Indiferente</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='2' name="question25_3" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>2. Poco Motivante</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='1' name="question25_3" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>1. Muy Desmotivante</span>
                                            </label> 
                                        </p>
                                    </div>
                                    <div className='input-field col s12'>
                                        <p>
                                            <label>{thirdQuestions[12]}</label>
                                        </p>
                                        <p className='col s12'>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input required value='5' name="question25_4" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>5. Muy Motivante</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='4' name="question25_4" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>4. Algo Motivante</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='3' name="question25_4" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>3. Indiferente</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='2' name="question25_4" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>2. Poco Motivante</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='1' name="question25_4" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>1. Muy Desmotivante</span>
                                            </label> 
                                        </p>
                                    </div>
                                    <div className='input-field col s12'>
                                        <p>
                                            <label>{thirdQuestions[13]}</label>
                                        </p>
                                        <p className='col s12'>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input required value='5' name="question25_5" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>5. Muy Motivante</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='4' name="question25_5" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>4. Algo Motivante</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='3' name="question25_5" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>3. Indiferente</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='2' name="question25_5" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>2. Poco Motivante</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='1' name="question25_5" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>1. Muy Desmotivante</span>
                                            </label> 
                                        </p>
                                    </div>
                                    <div className='input-field col s12'>
                                        <p>
                                            <label>{thirdQuestions[14]}</label>
                                        </p>
                                        <p className='col s12'>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input required value='5' name="question25_6" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>5. Muy Motivante</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='4' name="question25_6" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>4. Algo Motivante</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='3' name="question25_6" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>3. Indiferente</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='2' name="question25_6" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>2. Poco Motivante</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='1' name="question25_6" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>1. Muy Desmotivante</span>
                                            </label> 
                                        </p>
                                    </div>
                                    <div className='input-field col s12'>
                                        <p>
                                            <label>{thirdQuestions[15]}</label>
                                        </p>
                                        <p className='col s12'>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input required value='5' name="question25_7" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>5. Muy Motivante</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='4' name="question25_7" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>4. Algo Motivante</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='3' name="question25_7" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>3. Indiferente</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='2' name="question25_7" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>2. Poco Motivante</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='1' name="question25_7" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>1. Muy Desmotivante</span>
                                            </label> 
                                        </p>
                                    </div>
                                    <div className='input-field col s12'>
                                        <p>
                                            <label>{thirdQuestions[16]}</label>
                                        </p>
                                        <p className='col s12'>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input required value='5' name="question25_8" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>5. Muy Motivante</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='4' name="question25_8" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>4. Algo Motivante</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='3' name="question25_8" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>3. Indiferente</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='2' name="question25_8" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>2. Poco Motivante</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='1' name="question25_8" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>1. Muy Desmotivante</span>
                                            </label> 
                                        </p>
                                    </div>
                                    <div className='input-field col s12'>
                                        <p>
                                            <label>{thirdQuestions[17]}</label>
                                        </p>
                                        <p className='col s12'>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input required value='5' name="question25_9" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>5. Muy Motivante</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='4' name="question25_9" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>4. Algo Motivante</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='3' name="question25_9" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>3. Indiferente</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='2' name="question25_9" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>2. Poco Motivante</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='1' name="question25_9" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>1. Muy Desmotivante</span>
                                            </label> 
                                        </p>
                                    </div>
                                    <div className='input-field col s12'>
                                        <p>
                                            <label>{thirdQuestions[18]}</label>
                                        </p>
                                        <p className='col s12'>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input required value='5' name="question25_10" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>5. Muy Motivante</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='4' name="question25_10" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>4. Algo Motivante</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='3' name="question25_10" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>3. Indiferente</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='2' name="question25_10" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>2. Poco Motivante</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='1' name="question25_10" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>1. Muy Desmotivante</span>
                                            </label> 
                                        </p>
                                    </div>
                                    <div className='input-field col s12'>
                                        <p>
                                            <label>{thirdQuestions[19]}</label>
                                        </p>
                                        <p className='col s12'>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input required value='5' name="question25_11" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>5. Muy Motivante</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='4' name="question25_11" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>4. Algo Motivante</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='3' name="question25_11" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>3. Indiferente</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='2' name="question25_11" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>2. Poco Motivante</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='1' name="question25_11" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>1. Muy Desmotivante</span>
                                            </label> 
                                        </p>
                                    </div>
                                    <div className='input-field col s12'>
                                        <p>
                                            <label>{thirdQuestions[20]}</label>
                                        </p>
                                        <p className='col s12'>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input required value='5' name="question25_12" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>5. Muy Motivante</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='4' name="question25_12" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>4. Algo Motivante</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='3' name="question25_12" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>3. Indiferente</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='2' name="question25_12" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>2. Poco Motivante</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='1' name="question25_12" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>1. Muy Desmotivante</span>
                                            </label> 
                                        </p>
                                    </div>
                                    <div className='input-field col s12'>
                                        <p>
                                            <label>{thirdQuestions[21]}</label>
                                        </p>
                                        <p className='col s12'>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input required value='5' name="question25_13" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>5. Muy Motivante</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='4' name="question25_13" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>4. Algo Motivante</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='3' name="question25_13" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>3. Indiferente</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='2' name="question25_13" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>2. Poco Motivante</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='1' name="question25_13" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>1. Muy Desmotivante</span>
                                            </label> 
                                        </p>
                                    </div>
                                    <div className='input-field col s12'>
                                        <p>
                                            <label>{thirdQuestions[22]}</label>
                                        </p>
                                        <p className='col s12'>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input required value='5' name="question25_14" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>5. Muy Motivante</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='4' name="question25_14" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>4. Algo Motivante</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='3' name="question25_14" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>3. Indiferente</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='2' name="question25_14" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>2. Poco Motivante</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='1' name="question25_14" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>1. Muy Desmotivante</span>
                                            </label> 
                                        </p>
                                    </div>
                                    <div className='input-field col s12'>
                                        <p>
                                            <label>{thirdQuestions[23]}</label>
                                        </p>
                                        <p className='col s12'>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input required value='5' name="question25_15" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>5. Muy Motivante</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='4' name="question25_15" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>4. Algo Motivante</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='3' name="question25_15" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>3. Indiferente</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='2' name="question25_15" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>2. Poco Motivante</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='1' name="question25_15" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>1. Muy Desmotivante</span>
                                            </label> 
                                        </p>
                                    </div>
                                    <div className='input-field col s12'>
                                        <p>
                                            <label>{thirdQuestions[24]}</label>
                                        </p>
                                        <p className='col s12'>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input required value='5' name="question25_16" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>5. Muy Motivante</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='4' name="question25_16" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>4. Algo Motivante</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='3' name="question25_16" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>3. Indiferente</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='2' name="question25_16" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>2. Poco Motivante</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='1' name="question25_16" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>1. Muy Desmotivante</span>
                                            </label> 
                                        </p>
                                    </div>
                                    <div className='input-field col s12'>
                                        <p>
                                            <label>{thirdQuestions[25]}</label>
                                        </p>
                                        <p className='col s12'>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input required value='5' name="question25_17" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>5. Muy Motivante</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='4' name="question25_17" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>4. Algo Motivante</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='3' name="question25_17" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>3. Indiferente</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='2' name="question25_17" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>2. Poco Motivante</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='1' name="question25_17" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>1. Muy Desmotivante</span>
                                            </label> 
                                        </p>
                                    </div>
                                    <div className='input-field col s12'>
                                        <p>
                                            <label>{thirdQuestions[26]}</label>
                                        </p>
                                        <p className='col s12'>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input required value='5' name="question25_18" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>5. Muy Motivante</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='4' name="question25_18" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>4. Algo Motivante</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='3' name="question25_18" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>3. Indiferente</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='2' name="question25_18" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>2. Poco Motivante</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='1' name="question25_18" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>1. Muy Desmotivante</span>
                                            </label> 
                                        </p>
                                    </div>
                                    <div className='input-field col s12'>
                                        <p>
                                            <label>{thirdQuestions[27]}</label>
                                        </p>
                                        <p className='col s12'>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input required value='5' name="question25_19" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>5. Muy Motivante</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='4' name="question25_19" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>4. Algo Motivante</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='3' name="question25_19" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>3. Indiferente</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='2' name="question25_19" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>2. Poco Motivante</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='1' name="question25_19" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>1. Muy Desmotivante</span>
                                            </label> 
                                        </p>
                                    </div>
                                    <div className='input-field col s12'>
                                        <p>
                                            <label>{thirdQuestions[28]}</label>
                                        </p>
                                        <p className='col s12'>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input required value='5' name="question25_20" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>5. Muy Motivante</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='4' name="question25_20" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>4. Algo Motivante</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='3' name="question25_20" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>3. Indiferente</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='2' name="question25_20" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>2. Poco Motivante</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='1' name="question25_20" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>1. Muy Desmotivante</span>
                                            </label> 
                                        </p>
                                    </div>
                                    {/* Section 3.2 */}
                                </div>
                                <button onClick={this.validateForm3} className="btn waves-effect waves-light bg-btn" type="submit" name="action">
                                    Continuar
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        )
    }
}
export default ThirdSection