import React,{Component} from 'react'
import M from 'materialize-css/dist/js/materialize.min.js'

import './css/subjectiveSection.css'
let subjectiveQuestions = [
    '42. Usted percibe que su calidad de vida laboral actual en relación a la que tenía 5 años atrás es',
    '43. En comparación con el año 2018, percibe que su estado de salud actual es',
]
class SubjectiveSection extends Component{
    componentDidMount() {
        M.AutoInit();
    }
    // Proceed to next step
    continue = (event)=> {
        event.preventDefault();
        this.props.createFormSection5();
    }
    // Validate Message
    validateFormSubjective = ()=>{
        if(!this.props.question42){
            alert('Selecciona una opcion para la pregunta numero 42')
        }else if(!this.props.question43){
            alert('Selecciona una opcion para la pregunta numero 43')
        }
    }
    
render(){
    return (
        <div className='container'>
            <div className='row'>
                <div className='input-field col s12'>
                </div>
                <div className='col s12'>
                    <div className='card'>
                        <span className='subjectiveSectionBoxTitle col s12 subjectiveSectionBox'>Bienestar Subjetivo</span>
                        <div className='card-content'>
                            <form onSubmit={this.continue}>
                                <div className='row'>
                                    {/* Section subjective */}
                                    <div className='input-field col s12 separator'>
                                        <select required name='question42' onChange={this.props.handleChange}>
                                            <option value="">Seleccione una opción</option>
                                            <option value="2">Mejor </option>
                                            <option value="1">Igual</option>
                                            <option value="0">Peor</option>
                                        </select>
                                        {/* 33 */}
                                        <label>{subjectiveQuestions[0]}</label>
                                    </div>
                                    <div className='input-field col s12 separator'>
                                        <select required name='question43' onChange={this.props.handleChange}>
                                            <option value="">Seleccione una opción</option>
                                            <option value="3">Mejor </option>
                                            <option value="2">Igual</option>
                                            <option value="1">Peor</option>
                                        </select>
                                        {/* 33 */}
                                        <label>{subjectiveQuestions[1]}</label>
                                    </div>
                                    {/* Section subjective */}
                                </div>
                                <button onClick={this.validateFormSubjective} className="btn waves-effect waves-light bg-btn" type="submit" name="action">
                                    Enviar
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        )
    }
}
export default SubjectiveSection