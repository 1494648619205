import React,{Component} from 'react'
import M from 'materialize-css/dist/js/materialize.min.js'

import './css/fifthSection.css'
let fifthQuestions = [
    '44.  Durante el calendario académico, tener una jornada semestral para compartir en familia',
    'Se Anula!!! 45. Durante el calendario académico, tener flexibilidad en la solicitud de permisos. Se Anula!!!',
    '45. Semestralmente, tener  una campaña de salud oral',
    '46.  Semestralmente, tener una brigada de salud visual y auditiva',
    '47. Trimestralmente, tener campañas de salud sexual y reproductiva',
    '48. Diseñar y ejecutar programas para la prevención y control de enfermedades generadas por riesgos laborales: biomecánicos,  sobre uso de la voz, visual y auditiva',
    '49. Tener un plan de acompañamiento psicosocial que incluya a la familia',
    '50. Tener acompañamiento psicológico para el manejo distrés (estrés negativo) y el cansancio excesivo',
    '51. Visitas mensuales de la para verificar sus riesgos laborales',
    '52. Mantener actualizado el plan de seguridad e higiene de mi lugar de trabajo',
    '53. Habilitar una zona de esparcimiento para realizar la pausa activa durante la jornada',
]
class FifthSection extends Component{
    componentDidMount() {
        M.AutoInit();
    }
    // Proceed to next step
    continue = (event)=> {
        event.preventDefault();
        this.props.createFormSection6();
    }
    // Validate Message
    validateForm5 = ()=>{
        if(!this.props.question44){
            alert('Selecciona una opcion para la pregunta numero 44')
        } else if(!this.props.question45){
            alert('Selecciona una opcion para la pregunta numero 45')
        }else if(!this.props.question46){
            alert('Selecciona una opcion para la pregunta numero 46')
        }else if(!this.props.question47){
            alert('Selecciona una opcion para la pregunta numero 47')
        }else if(!this.props.question48){
            alert('Selecciona una opcion para la pregunta numero 48')
        }else if(!this.props.question49){
            alert('Selecciona una opcion para la pregunta numero 49')
        }else if(!this.props.question50){
            alert('Selecciona una opcion para la pregunta numero 50')
        }else if(!this.props.question51){
            alert('Selecciona una opcion para la pregunta numero 51')
        }else if(!this.props.question52){
            alert('Selecciona una opcion para la pregunta numero 52')
        }else if(!this.props.question53){
            alert('Selecciona una opcion para la pregunta numero 53')
        }
    }
    
render(){
    return (
        <div className='container'>
            <div className='row'>
                <div className='input-field col s12'>
                </div>
                <div className='col s12'>
                    <div className='card'>
                        <div className='fifthSectionBox'>
                            <span className='fifthSectionBoxTitle col s12'>V Sección: IDENTIFICACIÓN DE INTERESES Y NECESIDADES</span>
                            <span className='fifthSectionBoxSubTitle col s12'>I. Bienestar Ambiental: Horarios Laborales, Promoción y Prevención de la Salud y Espacio Físico </span>
                        </div>
                        <div className='card-content'>
                        <h6 className='fifthSectionBoxSubTitle'>A continuación, encontrará una serie 
                        de actividades agrupadas en tres categorías:<br></br><br></br>
                        - Horarios Laborales 
                        <br></br>
                        - Promoción y Prevención de la Salud 
                        <br></br>
                        - Espacio Físico 
                        <br></br><br></br>Por favor indique el grado de gusto 
                        por cada enunciado, considerando que:
                        <b> 3. significa que me interesa y me gusta</b>,
                        <b> 2. Me es indiferente</b>  y 
                        <b> 1. No me interesa ni me gusta. </b> 
                        Esto es para conocer cuáles son sus preferencias en el rol 
                        social que desempeña como docente o directivo docente. <br></br><br></br>
                        </h6>
                            <form onSubmit={this.continue}>
                                <div className='row'>
                                    <div className='col s12'>
                                        <span className='fifthLabelQuestion'>Horarios Laborales</span>
                                    </div>
                                    {/* Section 5.1 */}
                                    <div className='input-field col s12 separator'>
                                        <p>
                                            <label>{fifthQuestions[0]}</label>
                                        </p>
                                        <p className='col s12'>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input required value='3' name="question44" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>3. Me interesa y me gusta</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='2' name="question44" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>2. Me es indiferente</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='1' name="question44" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>1. No me interesa ni me gusta</span>
                                            </label> 
                                        </p>
                                    </div>
                                    {/* Section 5.1 */}
                                    <div className='col s12 separator'>
                                        <span className='fifthLabelQuestion'>Promoción y Prevención de la Salud</span>
                                    </div>
                                    {/* Section 5.2 */}
                                    <div className='input-field col s12 separator'>
                                        <p>
                                            <label>{fifthQuestions[2]}</label>
                                        </p>
                                        <p className='col s12'>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input required value='3' name="question45" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>3. Me interesa y me gusta</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='2' name="question45" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>2. Me es indiferente</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='1' name="question45" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>1. No me interesa ni me gusta</span>
                                            </label> 
                                        </p>
                                    </div>
                                    <div className='input-field col s12 separator'>
                                        <p>
                                            <label>{fifthQuestions[3]}</label>
                                        </p>
                                        <p className='col s12'>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input required value='3' name="question46" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>3. Me interesa y me gusta</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='2' name="question46" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>2. Me es indiferente</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='1' name="question46" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>1. No me interesa ni me gusta</span>
                                            </label> 
                                        </p>
                                    </div>
                                    <div className='input-field col s12 separator'>
                                        <p>
                                            <label>{fifthQuestions[4]}</label>
                                        </p>
                                        <p className='col s12'>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input required value='3' name="question47" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>3. Me interesa y me gusta</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='2' name="question47" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>2. Me es indiferente</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='1' name="question47" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>1. No me interesa ni me gusta</span>
                                            </label> 
                                        </p>
                                    </div>
                                    <div className='input-field col s12 separator'>
                                        <p>
                                            <label>{fifthQuestions[5]}</label>
                                        </p>
                                        <p className='col s12'>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input required value='3' name="question48" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>3. Me interesa y me gusta</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='2' name="question48" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>2. Me es indiferente</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='1' name="question48" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>1. No me interesa ni me gusta</span>
                                            </label> 
                                        </p>
                                    </div>
                                    <div className='input-field col s12 separator'>
                                        <p>
                                            <label>{fifthQuestions[6]}</label>
                                        </p>
                                        <p className='col s12'>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input required value='3' name="question49" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>3. Me interesa y me gusta</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='2' name="question49" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>2. Me es indiferente</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='1' name="question49" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>1. No me interesa ni me gusta</span>
                                            </label> 
                                        </p>
                                    </div>
                                    <div className='input-field col s12 separator'>
                                        <p>
                                            <label>{fifthQuestions[7]}</label>
                                        </p>
                                        <p className='col s12'>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input required value='3' name="question50" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>3. Me interesa y me gusta</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='2' name="question50" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>2. Me es indiferente</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='1' name="question50" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>1. No me interesa ni me gusta</span>
                                            </label> 
                                        </p>
                                    </div>
                                    {/* Section 5.2 */}
                                    <div className='col s12 separator'>
                                        <span className='fifthLabelQuestion'>Espacio Físico</span>
                                    </div>
                                    {/* Section 5.3 */}
                                    <div className='input-field col s12 separator'>
                                        <p>
                                            <label>{fifthQuestions[8]}</label>
                                        </p>
                                        <p className='col s12'>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input required value='3' name="question51" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>3. Me interesa y me gusta</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='2' name="question51" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>2. Me es indiferente</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='1' name="question51" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>1. No me interesa ni me gusta</span>
                                            </label> 
                                        </p>
                                    </div>
                                    <div className='input-field col s12 separator'>
                                        <p>
                                            <label>{fifthQuestions[9]}</label>
                                        </p>
                                        <p className='col s12'>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input required value='3' name="question52" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>3. Me interesa y me gusta</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='2' name="question52" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>2. Me es indiferente</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='1' name="question52" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>1. No me interesa ni me gusta</span>
                                            </label> 
                                        </p>
                                    </div>
                                    <div className='input-field col s12 separator'>
                                        <p>
                                            <label>{fifthQuestions[10]}</label>
                                        </p>
                                        <p className='col s12'>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input required value='3' name="question53" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>3. Me interesa y me gusta</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='2' name="question53" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>2. Me es indiferente</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='1' name="question53" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>1. No me interesa ni me gusta</span>
                                            </label> 
                                        </p>
                                    </div>
                                    {/* Section 5.3 */}
                                </div>
                                <button onClick={this.validateForm5} className="btn waves-effect waves-light bg-btn" type="submit" name="action">
                                    Enviar
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        )
    }
}
export default FifthSection