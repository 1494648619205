import React,{Component} from 'react'
import M from 'materialize-css/dist/js/materialize.min.js'

import './css/sixthSection.css'
let sixthQuestions = [
    '54. Realizar un curso de manualidades y artesanías',
    '55. Aprender o practicar la interpretación de algún instrumento musical',
    '56. Practicar bailes típicos, folclor colombiano y danzas modernas',
    'Se Anula!!! 58. Comisiones o encargos en otro municipio por un período máximo de seis meses (intercambio cultural) Se Anula!!!',
    '57. Tomar cursos de actualización en docencia',
    '58. Aprender otro idioma',
    '59. Aplicar a becas para educación formal',
    '60. Apoyo en la publicación de trabajos en medios de circulación nacional e internacional',
    '61. Financiación de investigaciones con impacto en la calidad educativa',
    '62. Orientación en presupuesto familiar',
]
class SixthSection extends Component{
    componentDidMount() {
        M.AutoInit();
    }
    // Proceed to next step
    continue = (event)=> {
        event.preventDefault();
        this.props.createFormSection7();
    }
    // Validate Message
    validateForm6 = ()=>{
        if(!this.props.question54){
            alert('Selecciona una opcion para la pregunta numero 54')
        } else if(!this.props.question55){
            alert('Selecciona una opcion para la pregunta numero 55')
        }else if(!this.props.question56){
            alert('Selecciona una opcion para la pregunta numero 56') 
        }else if(!this.props.question57){
            alert('Selecciona una opcion para la pregunta numero 57') 
        }else if(!this.props.question58){
            alert('Selecciona una opcion para la pregunta numero 58') 
        }else if(!this.props.question59){
            alert('Selecciona una opcion para la pregunta numero 59')
        }else if(!this.props.question60){
            alert('Selecciona una opcion para la pregunta numero 60')
        }else if(!this.props.question61){
            alert('Selecciona una opcion para la pregunta numero 61')
        }else if(!this.props.question62){
            alert('Selecciona una opcion para la pregunta numero 62')
    }
}
    
render(){
    return (
        <div className='container'>
            <div className='row'>
                <div className='input-field col s12'>
                </div>
                <div className='col s12'>
                    <div className='card'>
                        <div className='sixthSectionBox'>
                            <span className='fifthSectionBoxTitle col s12'>II. Bienestar Subjetivo: <br></br> Artística y cultural, Educación y profesionalización</span>
                        </div>
                        <div className='card-content'>
                        <h6 className='fGeneralDetailsSubTitle'>A continuación, encontrará una serie de actividades 
                        agrupadas en dos categorías:
                        <br></br><br></br>
                        - Artística y Cultural
                        <br></br>
                        - Educación y Profesionalización
                        <br></br><br></br>Por favor, indique el grado de gusto por 
                        cada premisa, considerando que: 3 significa que le gusta mucho, 2 que le es indiferente y 1 que le interesa poco. 
                        <br></br><br></br>Esto es para conocer cuáles son sus preferencias en el rol social que 
                        desempeña como Docente o Directivo Docente. 
                        <br></br><br></br> 
                        </h6>
                            <form onSubmit={this.continue}>
                                <div className='row'>
                                    <div className='col s12 separator'>
                                        <span className='fifthLabelQuestion'>Artística y cultural</span>
                                    </div>
                                    {/* Section 6.1 */}
                                    <div className='input-field col s12 separator'>
                                        <p>
                                            <label>{sixthQuestions[0]}</label>
                                        </p>
                                        <p className='col s12'>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input required value='3' name="question54" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>3. Me interesa mucho</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='2' name="question54" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>2. Me es indiferente</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='1' name="question54" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>1. Me interesa poco</span>
                                            </label> 
                                        </p>
                                    </div>
                                    <div className='input-field col s12 separator'>
                                        <p>
                                            <label>{sixthQuestions[1]}</label>
                                        </p>
                                        <p className='col s12'>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input required value='3' name="question55" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>3. Me interesa mucho</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='2' name="question55" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>2. Me es indiferente</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='1' name="question55" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>1. Me interesa poco</span>
                                            </label> 
                                        </p>
                                    </div>
                                    <div className='input-field col s12 separator'>
                                        <p>
                                            <label>{sixthQuestions[2]}</label>
                                        </p>
                                        <p className='col s12'>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input required value='3' name="question56" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>3. Me interesa mucho</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='2' name="question56" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>2. Me es indiferente</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='1' name="question56" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>1. Me interesa poco</span>
                                            </label> 
                                        </p>
                                    </div>
                                    {/* Section 6.1 */}
                                    <div className='col s12 separator'>
                                        <span className='fifthLabelQuestion'>Educación y Profesionalización</span>
                                    </div>
                                    {/* Section 6.2 */}
                                    <div className='input-field col s12 separator'>
                                        <p>
                                            <label>{sixthQuestions[4]}</label>
                                        </p>
                                        <p className='col s12'>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input required value='3' name="question57" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>3. Me interesa mucho</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='2' name="question57" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>2. Me es indiferente</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='1' name="question57" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>1. Me interesa poco</span>
                                            </label> 
                                        </p>
                                    </div>
                                    <div className='input-field col s12 separator'>
                                        <p>
                                            <label>{sixthQuestions[5]}</label>
                                        </p>
                                        <p className='col s12'>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input required value='3' name="question58" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>3. Me interesa mucho</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='2' name="question58" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>2. Me es indiferente</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='1' name="question58" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>1. Me interesa poco</span>
                                            </label> 
                                        </p>
                                    </div>
                                    <div className='input-field col s12 separator'>
                                        <p>
                                            <label>{sixthQuestions[6]}</label>
                                        </p>
                                        <p className='col s12'>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input required value='3' name="question59" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>3. Me interesa mucho</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='2' name="question59" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>2. Me es indiferente</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='1' name="question59" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>1. Me interesa poco</span>
                                            </label> 
                                        </p>
                                    </div>
                                    <div className='input-field col s12 separator'>
                                        <p>
                                            <label>{sixthQuestions[7]}</label>
                                        </p>
                                        <p className='col s12'>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input required value='3' name="question60" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>3. Me interesa mucho</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='2' name="question60" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>2. Me es indiferente</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='1' name="question60" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>1. Me interesa poco</span>
                                            </label> 
                                        </p>
                                    </div>
                                    <div className='input-field col s12 separator'>
                                        <p>
                                            <label>{sixthQuestions[8]}</label>
                                        </p>
                                        <p className='col s12'>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input required value='3' name="question61" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>3. Me interesa mucho</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='2' name="question61" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>2. Me es indiferente</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='1' name="question61" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>1. Me interesa poco</span>
                                            </label> 
                                        </p>
                                    </div>
                                    <div className='input-field col s12 separator'>
                                        <p>
                                            <label>{sixthQuestions[9]}</label>
                                        </p>
                                        <p className='col s12'>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input required value='3' name="question62" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>3. Me interesa mucho</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='2' name="question62" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>2. Me es indiferente</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='1' name="question62" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>1. Me interesa poco</span>
                                            </label> 
                                        </p>
                                    </div>
                                    {/* Section 6.2 */}
                                </div>
                                <button onClick={this.validateForm6} className="btn waves-effect waves-light bg-btn" type="submit" name="action">
                                    Enviar
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        )
    }
}
export default SixthSection