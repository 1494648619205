import React,{Component} from 'react'
import {Link} from 'react-router-dom'
import './css/indications.css'
import M from 'materialize-css/dist/js/materialize.min.js'
class Indications extends Component{
    componentDidMount() {
        M.AutoInit();
    }
    // Proceed to next step
    continue = (event)=> {
        event.preventDefault();
    }
    validateCheckpoint = ()=>{
        this.props.validateID()
    }
    render(){
        return(
            <div className='container'>
                <h4 className='instructionsTitle'>Acuerdo de confidencialidad</h4>
                <p className='instructionParagraph'>
                El Ministerio de Educación Nacional (MEN) quiere conocer la percepción 
                sobre el bienestar laboral de los Docentes y Directivos 
                Docentes de Preescolar, Básica y Media del 
                sector educativo público. Con el Convenio Interadministrativo 0133 de 
                2019, Desarrollos Políticos y Técnicos para el Fortalecimiento de la 
                Educación Inicial, Preescolar, Básica y Media, el MEN contrató los 
                servicios de la Universidad Pedagógica Nacional para realizar, entre 
                otros, la Formulación de la Política Integral de Bienestar Laboral 
                dirigida a directivos docentes y docentes de los establecimientos 
                educativos, para lo cual el siguiente cuestionario se propone recoger 
                información sobre el nivel de satisfacción laboral, en términos de 
                bienestar. 
                <br></br> <br></br>
                Su participación es voluntaria y su identidad, así como las respuestas
                suministradas serán de carácter confidencial. Sus datos personales y 
                las respuestas suministradas serán utilizadas únicamente para fines 
                estadísticos e investigativos. Sus conocimientos aportan al estudio 
                información relevante y pertinente para impactar de manera positiva 
                en el Bienestar Laboral Docente; agradecemos mucho su importante y 
                valiosa participación. 
                </p>
                <button className="btn waves-effect waves-light modal-trigger bg-btn bg-btnPilot" href="#modal1" type="submit" name="conditions">Terminos y Condiciones
                </button>
                <div id="modal1" className="modal">
                    <div className="modal-content">
                        <h4>Política de manejo de datos</h4>
                        <p className='instructionParagraph'>
                            De acuerdo a la Ley 1581 de 2012 el Ministerio de Educación 
                            Nacional es responsable del tratamiento de los datos 
                            personales; estos serán incluidos en nuestra base de datos 
                            y utilizados para las siguientes finalidades:
                        </p>
                        <ul>
                            <li className='instructionParagraph'>
                                1. Levantar información relevante para el presente estudio de Bienestar Laboral Docente.
                            </li>
                            <li className='instructionParagraph'>
                                2. Evitar la repetición y duplicidad en el registro de información. 
                            </li>
                        </ul>
                        <p className='instructionParagraph'>
                            Usted podrá ejercer sus derechos a conocer, actualizar, rectificar 
                            sus datos personales, hacer consultas y reclamos, y demás derechos 
                            indicados en el artículo 8° de la ley 1581 de 2012, y las demás 
                            normas que lo complementen o sustituyan.
                        </p>
                        <div className='input-field col s12'>
                            <form onSubmit={this.continue}>
                                <label htmlFor='identity_document'>Documento de Identidad</label>
                                    <input 
                                        className="validate"
                                        required
                                        maxLength="10"
                                        minLength="7"
                                        max="9999999999"
                                        min="10000000"
                                        type='number' 
                                        name='identity_document'
                                        onChange={this.props.handleChange}/>
                                <span className='helper-text' data-error='Ingrese un numero de identificación valido' data-success='Numero de identificación valido'></span>
                                <div className="modal-footer">
                                    <button 
                                        onClick={this.validateCheckpoint} 
                                        disabled={!this.props.identity_document} 
                                        type="submit" 
                                        className="modal-close btn-flat waves-effect waves-light bg-btn bg-btnPilot">
                                            Acepto Política de tratamiento de datos
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Indications