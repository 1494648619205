import React, {Component} from 'react'
import logoMen from '../assets/images/menLogo.svg'
import logoUpn from '../assets/images/logoUpnWhite.svg'
import './css/navbar.css'
class Navbar extends Component{
    render(){
        const logoM = <img className='logoMen' src={logoMen} alt='logo ministerio de educación nacional'/>
        const logoU = <img className='logoUpn' src={logoUpn} alt='logo universidad pedagogica nacional'/>
        return(
            <React.Fragment>
                {/*Navigation*/}
                <nav className='navbar'>
                    <div className='navigator container'>
                        {logoM}
                        {logoU}
                    </div>
                </nav>
            </React.Fragment>
        )
    }
}
export default Navbar