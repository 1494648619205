import React, {Component} from 'react'
import './css/badge.css'
class Progress extends Component{
    render(){
        return(
            <div className='container'>
                <div className='row'>
                    <h5 className='col s10 left-align'><b>Progreso Encuesta</b></h5>
                    <h5 className='col s2 right-align'><b>{this.props.step}/14</b></h5>
                </div>
                <div className='progress'>
                    <div className='determinate' style={{width:`${this.props.stepProgess}%`}}></div>
                </div>
            </div>
        )
    }
}
export default Progress

