import React,{Component} from 'react'
import M from 'materialize-css/dist/js/materialize.min.js'
import './css/firstSection.css'
let firstQuestions = [
    'Nombres y apellidos',
    'Documento de Identidad',
    'Edad',
    'Teléfono de contacto',
    'Sexo',
    'Género',
    'Estado Civil ',
    '¿Cuántos hijos tiene?',
    'Actualmente, ¿tiene recomendaciones médicas por alguna enfermedad laboral?',
    'Indique su nivel máximo de escolaridad',
    'Indique si Usted se encuentra en situación de',
    'Restitución de derechos ',
    'Reubicación por emergencia',
    'Discapacidad',
    'Desplazamiento',
    'Desalojo',
    'Protección (víctima)',
    'Pobreza extrema',
    'Reintegración a la vida civil',
    'Tipo de tenencia de la vivienda en la que reside actualmente',
    'Si Usted pertenece a un grupo étnico, indique cuál',
    'Departamento donde se ubica el Establecimiento Educativo',
    'Municipio donde se ubica el Establecimiento Educativo',
    'Nombre del Establecimiento Educativo donde trabaja',
    'Zona del Establecimiento Educativo',
    '¿Cuántos estudiantes tiene el establecimiento educativo donde Usted trabaja?',
    '¿Cuántos grupos de estudiantes tiene a su cargo?',
    'En total, ¿cuántos estudiantes tiene a su cargo?',
    'Su vinculación con el establecimiento educativo  es como',
    '¿tiene asignación académica?',
    '¿Cuántos años de experiencia tiene en el cargo que desempeña?',
    '¿Cuál es su categoría en el escalafón docente?',
    '¿A cuál estatuto docente pertenece?'
]
let firstNameInput = [
    'full_name',
    'identity_document',
    'age',
    'contact_phone',
    'sex',
    'gender',
    'civil_status',
    'number_of_childrens',
    'occupational_disease',
    'education_level',
    'situation1',
    'situation2',
    'situation3',
    'situation4',
    'situation5',
    'situation6',
    'situation7',
    'situation8',
    'property',
    'ethnic_group',
    'department_ei',
    'municipality_ei',
    'name_ei',
    'zone',
    'student_volume',
    'student_group',
    'student_in_charge',
    'bonding',
    'academic_assignment',
    'years_of_experience',
    'category_in_the_ranks',
    'statute'
]
class FirstSection extends Component{
    componentDidMount() {
        M.AutoInit();
    }
    // Proceed to next step
    continue = (event)=> {
        event.preventDefault();
        this.props.createFormSection1();
    }
    // Validate Message
    validateForm1 = ()=>{
        if(!this.props.full_name){
            alert('Escriba su nombre por favor')
        }  
        else if(!this.props.email){
            alert('Escriba su correo electronico por favor')
        }
        else if(!this.props.age){
            alert('Seleccione su edad por favor')
        }
        else if(!this.props.contact_phone){
            alert('Escriba un numero telefónico por favor')
        }
        else if(!this.props.sex){
            alert('Seleccione su sexo por favor')
        }
        else if(!this.props.gender){
            alert('Seleccione su genero por favor')
        }
        else if(!this.props.civil_status){
            alert('Seleccione su estado civil por favor')
        }
        else if(!this.props.number_of_childrens){
            alert('Seleccione su numero de hijos por favor')
        }
        else if(!this.props.occupational_disease){
            alert('Seleccione su tiene tiene recomendaciones médicas por sufrir de alguna enfermedad laboral por favor')
        }
        else if(!this.props.education_level){
            alert('Seleccione su nivel máximo de escolaridad por favor')
        }
        else if(!this.props.situation1){
            alert('Seleccione si o no para Restitución de derechos por favor')
        }
        else if(!this.props.situation2){
            alert('Seleccione si o no para Reubicación por emergencia por favor')
        }
        else if(!this.props.situation3){
            alert('Seleccione si o no para Discapacidad por favor')
        }
        else if(!this.props.situation4){
            alert('Seleccione si o no para Desplazamiento por favor')
        }
        else if(!this.props.situation5){
            alert('Seleccione si o no para Desalojo por favor')
        }
        else if(!this.props.situation6){
            alert('Seleccione si o no para Protección (víctima) por favor')
        }
        else if(!this.props.situation7){
            alert('Seleccione si o no para Pobreza extrema por favor')
        }
        else if(!this.props.situation8){
            alert('Seleccione si o no para Reintegración a la vida civil por favor')
        }
        else if(!this.props.property){
            alert('Seleccione Tipo de tenencia de la vivienda en la que reside actualmente por favor')
        }
        else if(!this.props.ethnic_group){
            alert('Seleccione alguna opción por favor si pertenece a un grupo étnico')
        }
        // else if(!this.props.department_ei){
        //     alert('Seleccione el Departamento donde se ubica el Establecimiento Educativo por favor')
        // }
        // else if(!this.props.municipality_ei){
        //     alert('Seleccione el Municipio donde se ubica el Establecimiento Educativo por favor')
        // }
        else if(!this.props.name_ei){
            alert('Seleccione el Nombre del Establecimiento Educativo donde trabaja por favor')
        }
        else if(!this.props.zone){
            alert('Seleccione la Zona del Establecimiento Educativo por favor')
        }
        else if(!this.props.student_volume){
            alert('Seleccione Cuántos estudiantes tiene el establecimiento educativo donde Usted trabaja por favor')
        }
        else if(!this.props.student_group){
            alert('Seleccione Cuántos grupos de estudiantes tiene a su cargo por favor')
        }
        else if(!this.props.student_in_charge){
            alert('Seleccione cuántos estudiantes tiene a su cargo por favor')
        }
        else if(!this.props.bonding){
            alert('Seleccione su vinculación con el establecimiento educativo por favor')
        }
        else if(!this.props.years_of_experience){
            alert('Seleccione cuántos años de experiencia tiene en el cargo que desempeña por favor')
        }
        else if(!this.props.category_in_the_ranks){
            alert('Seleccione cuál es su categoría en el escalafón docente por favor')
        }
        else if(!this.props.statute){
            alert('Seleccione a cuál estatuto docente pertenece por favor')
        }
    }
render(){
    let academic_assignment
    if(this.props.bonding === '3'){
        academic_assignment = 
            <div className='enabledSelect'>
                <select name={firstNameInput[28]} onChange={this.props.handleChange}>
                    <option value="">Seleccione Si o No</option>
                    <option value="1">SI</option>
                    <option value="0">NO</option>
                </select>
                <label htmlFor='academic_assignment'>{firstQuestions[29]}</label>
            </div>
    } else if(this.props.bonding !== '3') {
        academic_assignment = 
            <div className='disabledSelect'>
                <select name={firstNameInput[28]} onChange={this.props.handleChange}>
                    <option value="">Seleccione Si o No</option>
                    <option value="1">SI</option>
                    <option value="0">NO</option>
                </select>
                <label htmlFor='academic_assignment'>{firstQuestions[29]}</label>
            </div>
    }
    return (
        <div className='container'>
            <div className='row'>
                <div className='col s12'>
                    <div className='card'>
                    <span className='fGeneralDetailsTitle firstSectionBox col s12'>I. Sección:  Datos Sociodemográficos</span>
                        <div className='card-content'>
                            <form onSubmit={this.continue}>
                                <div className='row'>
                                    {/* Section 1.1 */}
                                    <div className='input-field col s5'>
                                        {/* <label htmlFor='full_name'>{firstQuestions[0]}</label> */}
                                        <input 
                                            placeholder='Nombres y apellidos'
                                            className="validate"
                                            required
                                            maxLength="50"
                                            type='text' 
                                            name={firstNameInput[0]}
                                            onChange={this.props.handleChange}/>
                                    </div>
                                    <div className='input-field col s4'>
                                        {/* <label htmlFor='email'>Correo Electrónico</label> */}
                                        <input 
                                            placeholder='Correo Electrónico'
                                            className='validate'
                                            type='email' 
                                            name='email'
                                            required
                                            onChange={this.props.handleChange}/>
                                        <span 
                                            className='helper-text' 
                                            data-error='Correo electronico no valido' 
                                            data-success='Correo electronico valido'>
                                        </span>
                                    </div>
                                    <div className='input-field col s3'>
                                        <select className="validate" required name={firstNameInput[2]} onChange={this.props.handleChange}>
                                            <option value="">Seleccione su Edad</option>
                                            {this.props.ages_data.map((item,i)=>{
                                                return(
                                                    <option key={i} value={item.age_id}>{item.age_teacher}</option>
                                                )
                                            })}
                                        </select>
                                        <label htmlFor='age'>{firstQuestions[2]}</label>
                                    </div>
                                    <div className='input-field col s12'>
                                        {/* <label htmlFor='contact_phone'>{firstQuestions[3]}</label> */}
                                        <input 
                                            placeholder='Teléfono de contacto'
                                            className="validate"
                                            required
                                            maxLength='10'
                                            minLength='7'
                                            type='tel' 
                                            name={firstNameInput[3]}
                                            onChange={this.props.handleChange} />
                                        <span 
                                            className='helper-text' 
                                            data-error='El campo debe contener valores numéricos y como mínimo debe ser de 7 dígitos para telefono fijo y 10 dígitos para celular' 
                                            data-success='numero telefónico valido'>
                                        </span>
                                    </div>
                                    <div className='input-field col s4'>
                                        <select className="validate" required name={firstNameInput[4]} onChange={this.props.handleChange}>
                                            <option value="">Seleccione su Sexo</option>
                                            <option value="1">Mujer </option>
                                            <option value="2">Hombre </option>
                                            <option value="3">Intersexual</option>
                                            <option value="0">No informa</option>
                                        </select>
                                        <label htmlFor='sex'>{firstQuestions[4]}</label>
                                    </div>
                                    <div className='input-field col s4'>
                                        <select className="validate" required name={firstNameInput[5]} onChange={this.props.handleChange}>
                                            <option value="">Seleccione su Género</option>
                                            <option value="1">Femenino</option>
                                            <option value="2">Masculino</option>
                                            <option value="3">Transgénero</option>
                                            <option value="0">No informa</option>
                                        </select>
                                        <label htmlFor='gender'>{firstQuestions[5]}</label>
                                    </div>
                                    <div className='input-field col s4'>
                                        <select className="validate" required name={firstNameInput[6]} onChange={this.props.handleChange}>
                                            <option value="">Seleccione su estado civil</option>
                                            <option value="1">Soltero (a)</option>
                                            <option value="2">Casado (a) </option>
                                            <option value="3">Viudo (a)</option>
                                            <option value="4">En unión libre</option>
                                            <option value="5">Separado (a) /divorciado (a)</option>
                                        </select>
                                        <label htmlFor='civil_status'>{firstQuestions[6]}</label>
                                    </div>
                                    <div className='input-field col s6'>
                                        <select className="validate" required name={firstNameInput[7]} onChange={this.props.handleChange}>
                                            <option value="">Seleccione una opción</option>
                                            {this.props.childrens_data.map((item,i)=>{
                                                return(
                                                    <option key={i} value={item.children_id}>{item.number_of_childrens}</option>
                                                )
                                            })}
                                        </select>
                                        <label htmlFor='number_of_childrens'>{firstQuestions[7]}</label>
                                    </div>
                                    <div className='input-field col s6'>
                                        <select className="validate" required name={firstNameInput[8]} onChange={this.props.handleChange}>
                                            <option value="">Seleccione una opción</option>
                                            <option value="1">SI</option>
                                            <option value="0">NO</option>
                                        </select>
                                        <label className='separatorQuestion' htmlFor='occupational_disease'>{firstQuestions[8]}</label>
                                    </div>
                                    <div className='input-field col s12'>
                                        <select className="validate" required name={firstNameInput[9]} onChange={this.props.handleChange}>
                                            <option value="">Seleccione su grado de formación</option>
                                            <option value="1">Bachiller</option>
                                            <option value="2">Bachiller pedagógico</option>
                                            <option value="3">Normalista superior</option>
                                            <option value="4">Técnico profesional</option>
                                            <option value="5">Tecnólogo</option>
                                            <option value="6">Profesional universitario</option>
                                            <option value="7">Especialista</option>
                                            <option value="8">Magíster</option>
                                            <option value="9">Doctor</option>
                                            <option value="10">Postdoctorado</option>
                                            
                                        </select>
                                        <label htmlFor='education_level'>{firstQuestions[9]}</label>
                                    </div>
                                    {/* Situations  */}
                                    <span className='labelQuestion col s12'>{firstQuestions[10]}</span>
                                    <div className='input-field col s5'>
                                        <label htmlFor='situation1'>{firstQuestions[11]}</label>
                                        <p className='radioAligner'>
                                            <label className='radioSeparator radioAlignerYes'>
                                                <input value='1' name="situation1" type="radio" onChange={this.props.handleChange}/>
                                                <span>SI</span>
                                            </label>                                        
                                            <label className='radioAlignerNo'>
                                                <input value='0' name="situation1" type="radio" onChange={this.props.handleChange}/>
                                                <span>No</span>
                                            </label>
                                        </p>
                                    </div>
                                    <div className='col s1'></div>
                                    <div className='input-field col s6'>
                                        <label htmlFor='situation2'>{firstQuestions[12]}</label>
                                        <p className='radioAligner'>
                                            <label className='radioSeparator radioAlignerYes'>
                                                <input value='1' name="situation2" type="radio" onChange={this.props.handleChange}/>
                                                <span>SI</span>
                                            </label>                                        
                                            <label className='radioAlignerNo'>
                                                <input value='0' name="situation2" type="radio" onChange={this.props.handleChange}/>
                                                <span>No</span>
                                            </label>
                                        </p>
                                    </div>
                                    <div className='input-field col s5'>
                                        <label htmlFor='situation3'>{firstQuestions[13]}</label>
                                        <p className='radioAligner'>
                                            <label className='radioSeparator radioAlignerYes'>
                                                <input value='1' name="situation3" type="radio" onChange={this.props.handleChange}/>
                                                <span>SI</span>
                                            </label>                                        
                                            <label className='radioAlignerNo'>
                                                <input value='0' name="situation3" type="radio" onChange={this.props.handleChange}/>
                                                <span>No</span>
                                            </label>
                                        </p>
                                    </div>
                                    <div className='col s1'></div>
                                    <div className='input-field col s6'>
                                        <label htmlFor='situation4'>{firstQuestions[14]}</label>
                                        <p className='radioAligner'>
                                            <label className='radioSeparator radioAlignerYes'>
                                                <input value='1' name="situation4" type="radio" onChange={this.props.handleChange}/>
                                                <span>SI</span>
                                            </label>                                        
                                            <label className='radioAlignerNo'>
                                                <input value='0' name="situation4" type="radio" onChange={this.props.handleChange}/>
                                                <span>No</span>
                                            </label>
                                        </p>
                                    </div>
                                    <div className='input-field col s5'>
                                        <label htmlFor='situation5'>{firstQuestions[15]}</label>
                                        <p className='radioAligner'>
                                            <label className='radioSeparator radioAlignerYes'>
                                                <input value='1' name="situation5" type="radio" onChange={this.props.handleChange}/>
                                                <span>SI</span>
                                            </label>                                        
                                            <label className='radioAlignerNo'>
                                                <input value='0' name="situation5" type="radio" onChange={this.props.handleChange}/>
                                                <span>No</span>
                                            </label>
                                        </p>
                                    </div>
                                    <div className='col s1'></div>
                                    <div className='input-field col s6'>
                                        <label htmlFor='situation6'>{firstQuestions[16]}</label>
                                        <p className='radioAligner'>
                                            <label className='radioSeparator radioAlignerYes'>
                                                <input value='1' name="situation6" type="radio" onChange={this.props.handleChange}/>
                                                <span>SI</span>
                                            </label>                                        
                                            <label className='radioAlignerNo'>
                                                <input value='0' name="situation6" type="radio" onChange={this.props.handleChange}/>
                                                <span>No</span>
                                            </label>
                                        </p>
                                    </div>
                                    <div className='input-field col s5'>
                                        <label htmlFor='situation7'>{firstQuestions[17]}</label>
                                        <p className='radioAligner'>
                                            <label className='radioSeparator radioAlignerYes'>
                                                <input value='1' name="situation7" type="radio" onChange={this.props.handleChange}/>
                                                <span>SI</span>
                                            </label>                                        
                                            <label className='radioAlignerNo'>
                                                <input value='0' name="situation7" type="radio" onChange={this.props.handleChange}/>
                                                <span>No</span>
                                            </label>
                                        </p>
                                    </div>
                                    <div className='col s1'></div>
                                    <div className='input-field col s6'>
                                        <label htmlFor='situation8'>{firstQuestions[18]}</label>
                                        <p className='radioAligner'>
                                            <label className='radioSeparator radioAlignerYes'>
                                                <input value='1' name="situation8" type="radio" onChange={this.props.handleChange}/>
                                                <span>SI</span>
                                            </label>                                        
                                            <label className='radioAlignerNo'>
                                                <input value='0' name="situation8" type="radio" onChange={this.props.handleChange}/>
                                                <span>No</span>
                                            </label>
                                        </p>
                                    </div>
                                    {/* Situations  */}
                                    {/* Section 1.1 */}
                                    <div className='separator col s12'></div>
                                    {/* Section 1.2 */}
                                    <div className='input-field col s6'>
                                        <select className="validate" required name={firstNameInput[18]} onChange={this.props.handleChange}>
                                            <option value="">Seleccione una opción</option>
                                            <option value="1">Propio</option>
                                            <option value="2">Arrendada</option>
                                            <option value="3">Familiar (no paga arriendo)</option>
                                            <option value="4">Familiar (paga arriendo)</option>
                                            <option value="5">Subarriendo</option>
                                            <option value="6">Ocupación de hecho</option>
                                            <option value="7">En usufructo</option>
                                            <option value="8">Propiedad colectiva </option>
                                            <option value="0">Otro tipo</option>
                                        </select>
                                        <label htmlFor='property'>{firstQuestions[19]}</label>
                                    </div>
                                    <div className='input-field col s6'>
                                        <select name={firstNameInput[19]} onChange={this.props.handleChange}>
                                            <option value="">Seleccione una opción</option>
                                            <option value="1">Indígenas</option>
                                            <option value="2">Rrom o Gitanos</option>
                                            <option value="3">Negros</option>
                                            <option value="4">Afrocolombianos</option>
                                            <option value="5">Raizales</option>
                                            <option value="6">Palenqueros</option>
                                            <option value="0">Ninguno</option>
                                        </select>
                                        <label>{firstQuestions[20]}</label>
                                    </div>
                                    <div className='input-field col s6'>
                                        {/* <label htmlFor='name_ei'>{firstQuestions[23]}</label> */}
                                        <input 
                                            placeholder='Nombre del Establecimiento Educativo donde trabaja'
                                            required
                                            type='text' 
                                            name={firstNameInput[22]}
                                            onChange={this.props.handleChange} />
                                    </div>
                                    <div className='input-field col s6'>
                                        <select required name={firstNameInput[23]} onChange={this.props.handleChange}>
                                            <option value="">Seleccione una zona</option>
                                            <option value="1">Rural</option>
                                            <option value="2">Urbana</option>
                                        </select>
                                        <label>{firstQuestions[24]}</label>
                                    </div>
                                    <div className='input-field col s12'>
                                        <select required name={firstNameInput[24]} onChange={this.props.handleChange}>
                                            <option value="">Seleccione una opción</option>
                                            <option value="1">Menos de 500 </option>
                                            <option value="2">Entre 500 y 1000 </option>
                                            <option value="3">Más 1000</option>
                                        </select>
                                        <label>{firstQuestions[25]}</label>
                                    </div>
                                    <div className='input-field col s12'>
                                        <select required name={firstNameInput[25]} onChange={this.props.handleChange}>
                                            <option value="">Seleccione una opción</option>
                                            {this.props.students_groups_data.map((item,i)=>{
                                                return(
                                                    <option key={i} value={item.students_groups_id}>{item.student_group}</option>
                                                )
                                            })}
                                        </select>
                                        <label htmlFor='student_group'>{firstQuestions[26]}</label>
                                    </div>
                                    <div className='input-field col s12'>
                                        <select required name={firstNameInput[26]} onChange={this.props.handleChange}>
                                            <option value="">Seleccione una opción</option>
                                            <option value="1">Entre 20 y 60</option>
                                            <option value="2">Entre 61 y 100</option>
                                            <option value="3">Entre 101 y 140</option>
                                            <option value="4">Entre 141 y 180</option>
                                            <option value="5">Entre 181 y 220</option>
                                            <option value="6">Entre 221 y 260</option>
                                            <option value="7">Entre 261 y 300</option>
                                            <option value="8">Entre 301 y 340</option>
                                            <option value="9">Entre 381 y 420</option>
                                            <option value="10">Entre 341 y 380</option>
                                            <option value="11">Entre 421 y 460</option>
                                            <option value="12">Entre 461 y 500</option>
                                            <option value="13">Entre 501 y 540</option>
                                            <option value="14">Entre 541 y 580</option>
                                            <option value="15">Entre 581 y 620</option>
                                            <option value="16">Más de 621</option>
                                        </select>
                                        <label htmlFor='student_in_charge'>{firstQuestions[27]}</label>
                                    </div>
                                    <div className='input-field col s6'>
                                        <select required name={firstNameInput[27]} onChange={this.props.handleChange}>
                                            <option value="">Seleccione una vinculación</option>
                                            <option value="1">Docente de Aula</option>
                                            <option value="2">Orientador(a)</option>
                                            <option value="3">Rector(a) </option>
                                            <option value="4">Coordinador (a) </option>
                                            <option value="5">Directores Rurales </option>
                                            <option value="6">Supervisores </option>
                                            <option value="7">Director de Núcleo  </option>
                                        </select>
                                        <label>{firstQuestions[28]}</label>
                                    </div>
                                    <div className='input-field col s6'>
                                        {academic_assignment}
                                    </div>
                                    <div className='input-field col s12'>
                                        <select required name={firstNameInput[29]} onChange={this.props.handleChange}>
                                            <option value="">Seleccione una opción</option>
                                            {this.props.years_of_experience_data.map((item,i)=>{
                                                return(
                                                    <option key={i} value={item.years_of_experience_id}>{item.years_of_experience}</option>
                                                )
                                            })}
                                        </select>
                                        <label>{firstQuestions[30]}</label>
                                    </div>
                                    <div className='input-field col s6'>
                                        <select required name={firstNameInput[30]} onChange={this.props.handleChange}>
                                            <option value="">Seleccione una categoría</option>
                                            {this.props.category_in_the_ranks_data.map((item,i)=>{
                                                return(
                                                    <option key={i} value={item.category_in_the_ranks_id}>{item.grade}</option>
                                                )
                                            })}
                                            <option value="27">Ninguno de los anteriores</option>
                                        </select>
                                        <label>¿Cuál es su categoría en el escalafón docente?</label>
                                    </div>
                                    <div className='input-field col s6'>
                                        <select required name={firstNameInput[31]} onChange={this.props.handleChange}>
                                            <option value="">Seleccione un estatuto</option>
                                            <option value="1">Decreto 2277 de 1979</option>
                                            <option value="2">Decreto 1278 de 2002</option>
                                            <option value="3">Decreto 804 de 1995</option>
                                        </select>
                                        <label>¿A cuál estatuto docente pertenece?</label>
                                    </div>
                                    {/* Section 1.2 */}
                                </div>
                                <button onClick={this.validateForm1} className="btn waves-effect waves-light bg-btn" type="submit" name="action">
                                    Continuar
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        )
    }
}
export default FirstSection