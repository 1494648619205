import React, {Component} from 'react'
import './css/badge.css'
class Badge extends Component{
    render(){
        return(
            <div className='container center'>
                <h5 className='blue-text text-darken-2 lema'>
                    <b>
                        ENCUESTA SOBRE BIENESTAR LABORAL DOCENTE
                    </b>
                </h5>
            </div>
        )
    }
}
export default Badge