import React,{Component} from 'react'
import M from 'materialize-css/dist/js/materialize.min.js'

import './css/fourthSection.css'
let fourthQuestions = [
    '26. En el desarrollo de mis labores tiendo a sentir mucho cansancio por la jornada',
    '27. En el lugar de trabajo las relaciones personales son cálidas y fraternas entre colegas',
    '28. Durante el último año, he experimentado sensaciones de vergüenza en el ambiente laboral ',
    '29. En mi ambiente de trabajo, se resaltan públicamente los logros individuales y, en privado, se hacen los llamados de atención sobre los aspectos a mejorar',
    '30. Tengo libertad para elegir las actividades de recreación, cultura y deporte en las que quiero participar',
    '31. Durante el último año, he dejado de asistir a eventos familiares y/o sociales relevantes por responder a actividades laborales adicionales (horas extras)',
    '32. La mayoría de los padres de familia y/o tutores de mis estudiantes apoyan mi labor docente y manifiestan su agradecimiento y confianza',
    '33. La mayoría de mis estudiantes me manifiestan su agradecimiento y reconocen con respeto mi labor',
    '34. Durante el último año, he tenido las oportunidades y los medios para progresar en mi carrera docente',
    '35. Durante la jornada laboral y las actividades de integración, disfruto de la compañía de mis compañeros, directivos y administrativos',
    '36. En el ambiente laboral, puedo exponer críticas constructivas y opiniones sin temor a represalias',
    '37. En el  ambiente laboral, puedo desarrollar mi labor desde principios de libertad de cátedra, libertad de expresión y autonomía',
    '38. Durante el último año, he contado con planes de formación ofrecidos por el Ministerio de Educación Nacional o la Secretaría de Educación',
    '¿Cuáles?',
    '39. En mi trabajo he recibido orientación para acceder a programas de compra de vivienda',
    '40. En el ambiente laboral, dedico tiempo a mantener relaciones interpersonales respetuosas y colaborativas',
    '41. Conforme a su gestión como Docente y/o Directivo Docente, indique qué tan satisfecho se encuentra con los siguientes aspectos:',
    '41-1. Satisfacción con los recursos tecnológicos ',
    '41-2. Los incentivos cognitivos (vinculación en redes académicas, comisiones, publicaciones, becas, reconocimientos)',
    '41-3. Los incentivos económicos (como financiación de estudios)',
    '41-4. La confianza entre equipo docentes y el equipo directivo docente',
    '41-5. Las pasantías en otros países',
    '41-6. El reconocimiento a nivel nacional e internacional  ',
    '41-7. Becas de educación para hijos o cónyuge',
    '41-8. Participación en eventos académicos',
    '41-9. Ascensos en el escalafón y promoción profesional',
    '41-10. Oportunidades para presentar investigaciones académicas',
    '41-11. Espacios de recreación, cultura  y deporte durante la jornada',
    '41-12. Apoyo para las publicaciones',
    '41-13. Elección de su lugar de trabajo',
    '41-14. Programas de prevención de enfermedades laborales',
    '41-15. Atención oportuna ante la solicitud de traslado',
    '41-16. Cambio de roles durante el año escolar',
    '41-17.  La agrupación y afiliación a sindicatos',
    '41-18. La formación de capacidades y  competencias docentes',
    '41-19. La infraestructura educativa',
    '41-20. El poder de decisión y participación',
    '41-21. La organización de equipos de trabajo ',
    '41-22. La distribución equilibrada de cargas laborales ',
    '41-23. La recreación y el tiempo de ocio ',
    '41-24. La inversión en recursos tecnológicos',
    '41-25. El acompañamiento psicosocial ',
    '41-26. Trabajo interdisciplinario y en equipo',
    '41-27-1. Apoyo para acceder a créditos ',
    '41-27-2. Apoyo para acceder a vivienda y/o a proyectos de adquisición de vivienda',
    '41-28. Programas de  preparación para la jubilación ',
]
class FourthSection extends Component{
    componentDidMount() {
        M.AutoInit();
    }
    // Proceed to next step
    continue = (event)=> {
        event.preventDefault();
        this.props.createFormSection4();
    }
    // Validate Message
    validateForm4 = ()=>{
        if(!this.props.question26){
            alert('Selecciona una opcion para la pregunta numero 26')
        }
        else if(!this.props.question27){
            alert('Selecciona una opcion para la pregunta numero 27')
        }
        else if(!this.props.question28){
            alert('Selecciona una opcion para la pregunta numero 28')
        }
        else if(!this.props.question29){
            alert('Selecciona una opcion para la pregunta numero 29')
        }
        else if(!this.props.question30){
            alert('Selecciona una opcion para la pregunta numero 30')
        }
        else if(!this.props.question31){
            alert('Selecciona una opcion para la pregunta numero 31')
        }
        else if(!this.props.question32){
            alert('Selecciona una opcion para la pregunta numero 32')
        }
        else if(!this.props.question33){
            alert('Selecciona una opcion para la pregunta numero 33')
        }
        else if(!this.props.question34){
            alert('Selecciona una opcion para la pregunta numero 34')
        }
        else if(!this.props.question35){
            alert('Selecciona una opcion para la pregunta numero 35')
        }
        else if(!this.props.question36){
            alert('Selecciona una opcion para la pregunta numero 36')
        }
        else if(!this.props.question37){
            alert('Selecciona una opcion para la pregunta numero 37')
        }
        else if(!this.props.question38){
            alert('Selecciona una opcion para la pregunta numero 38')
        }
        else if(!this.props.question39){
            alert('Selecciona una opcion para la pregunta numero 39')
        }
        else if(!this.props.question40){
            alert('Selecciona una opcion para la pregunta numero 40')
        }
        else if(!this.props.question41_1){
            alert('Selecciona una opcion para la pregunta numero 41-1')
        }
        else if(!this.props.question41_2){
            alert('Selecciona una opcion para la pregunta numero 41-2')
        }
        else if(!this.props.question41_3){
            alert('Selecciona una opcion para la pregunta numero 41-3')
        }
        else if(!this.props.question41_4){
            alert('Selecciona una opcion para la pregunta numero 41-4')
        }
        else if(!this.props.question41_5){
            alert('Selecciona una opcion para la pregunta numero 41-5')
        }
        else if(!this.props.question41_6){
            alert('Selecciona una opcion para la pregunta numero 41-6')
        }
        else if(!this.props.question41_7){
            alert('Selecciona una opcion para la pregunta numero 41-7')
        }
        else if(!this.props.question41_8){
            alert('Selecciona una opcion para la pregunta numero 41-8')
        }
        else if(!this.props.question41_9){
            alert('Selecciona una opcion para la pregunta numero 41-9')
        }
        else if(!this.props.question41_10){
            alert('Selecciona una opcion para la pregunta numero 41-10')
        }
        else if(!this.props.question41_11){
            alert('Selecciona una opcion para la pregunta numero 41-11')
        }
        else if(!this.props.question41_12){
            alert('Selecciona una opcion para la pregunta numero 41-12')
        }
        else if(!this.props.question41_13){
            alert('Selecciona una opcion para la pregunta numero 41-13')
        }
        else if(!this.props.question41_14){
            alert('Selecciona una opcion para la pregunta numero 41-14')
        }
        else if(!this.props.question41_15){
            alert('Selecciona una opcion para la pregunta numero 41-15')
        }
        else if(!this.props.question41_16){
            alert('Selecciona una opcion para la pregunta numero 41-16')
        }
        else if(!this.props.question41_17){
            alert('Selecciona una opcion para la pregunta numero 41-17')
        }
        else if(!this.props.question41_18){
            alert('Selecciona una opcion para la pregunta numero 41-18')
        }
        else if(!this.props.question41_19){
            alert('Selecciona una opcion para la pregunta numero 41-19')
        }
        else if(!this.props.question41_20){
            alert('Selecciona una opcion para la pregunta numero 41-20')
        }
        else if(!this.props.question41_21){
            alert('Selecciona una opcion para la pregunta numero 41-21')
        }
        else if(!this.props.question41_22){
            alert('Selecciona una opcion para la pregunta numero 41-22')
        }
        else if(!this.props.question41_23){
            alert('Selecciona una opcion para la pregunta numero 41-23')
        }
        else if(!this.props.question41_24){
            alert('Selecciona una opcion para la pregunta numero 41-24')
        }
        else if(!this.props.question41_25){
            alert('Selecciona una opcion para la pregunta numero 41-25')
        }
        else if(!this.props.question41_26){
            alert('Selecciona una opcion para la pregunta numero 41-26')
        }
        else if(!this.props.question41_27_1_1){
            alert('Selecciona una opcion para la pregunta numero 41-27-1')
        }
        else if(!this.props.question41_27_1_2){
            alert('Selecciona una opcion para la pregunta numero 41-27-2')
        }
        else if(!this.props.question41_28){
            alert('Selecciona una opcion para la pregunta numero 41-28')
        }
    }  
render(){
    let formation_plans
    if(this.props.question38 === '1'){
        formation_plans = 
            <div className='enabledSelect'>
                <select name='formation_plans' onChange={this.props.handleChange}>
                    <option value="">Seleccione una opción</option>
                    <option value="2">Licenciatura</option>
                    <option value="3">Especialización</option>
                    <option value="4">Maestría</option>
                    <option value="5">Doctorado </option>
                </select>
                <label htmlFor='formation_plans'>{fourthQuestions[13]}</label>
            </div>
    } else if(this.props.question38 !== '1') {
        formation_plans = 
            <div className='disabledSelect'>
                <select name='formation_plans' onChange={this.props.handleChange}>
                    <option value="">Seleccione una opción</option>
                    <option value="2">Licenciatura</option>
                    <option value="3">Especialización</option>
                    <option value="4">Maestría</option>
                    <option value="5">Doctorado </option>
                    <option value="5">Postdoctorado </option>
                </select>
                <label htmlFor='formation_plans'>{fourthQuestions[13]}</label>
            </div>
    }
    return (
        <div className='container'>
            <div className='row'>
                <div className='col s12'>
                    <div className='card'>
                        <div className='col s12 fourthSectionBox'>
                            <span className='fourthSectionBoxTitle'>IV Sección: Bienestar Interaccionista</span>
                            <span className='fourthSectionBoxSubTitle col s12'> Abarca la percepción y satisfacción frente a las relaciones entre factores ambientales objetivos y subjetivos.  También, aborda la percepción sobre la capacidad de respuesta docente respecto a las condiciones laborales dadas y de relacionamiento con los miembros de la comunidad educativa.</span>
                        </div>
                        <div className='card-content'>
                            <div className='input-field col s12'>
                                <h6 className='fGeneralDetailsSubTitle'>A continuación, encontrará una serie 
                                de afirmaciones. Por favor seleccione la opción de respuesta 
                                que considere más cercana a su modo de pensar, sentir  y actuar. Entre las 
                                opciones de respuesta, elija solo una. Recuerde que no existen 
                                respuestas correctas o incorrectas, así que responda con tranquilidad y honestidad.</h6>
                            </div>
                            <form onSubmit={this.continue}>
                                <div className='row'>
                                    {/* Section 3.1 */}
                                    <div className='input-field col s12 separator'>
                                        <p>
                                            <label>{fourthQuestions[0]}</label>
                                        </p>
                                        <p className='col s12'>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input required value='1' name="question26" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>Siempre</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='2' name="question26" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>La mayoría de las veces sí</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='3' name="question26" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>Algunas veces sí, algunas veces no</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='4' name="question26" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>La mayoría de las veces no</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='5' name="question26" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>Nunca</span>
                                            </label> 
                                        </p>
                                    </div>
                                    <div className='input-field col s12 separator'>
                                        <select required name='question27' onChange={this.props.handleChange}>
                                            <option value="">Seleccione una opción</option>
                                            <option value="5">Siempre </option>
                                            <option value="4">La mayoría de las veces sí</option>
                                            <option value="3">Algunas veces sí, algunas veces no </option>
                                            <option value="2">La mayoría de las veces no</option>
                                            <option value="1">Nunca	</option>
                                        </select>
                                        <label>{fourthQuestions[1]}</label>
                                    </div>
                                    <div className='input-field col s12 separator'>
                                        <select required name='question28' onChange={this.props.handleChange}>
                                            <option value="">Seleccione una opción</option>
                                            <option value="1">Siempre </option>
                                            <option value="2">La mayoría de las veces sí</option>
                                            <option value="3">Algunas veces sí, algunas veces no </option>
                                            <option value="4">La mayoría de las veces no</option>
                                            <option value="5">Nunca	</option>
                                        </select>
                                        <label>{fourthQuestions[2]}</label>
                                    </div>
                                    <div className='input-field col s12 separator'>
                                        <select required name='question29' onChange={this.props.handleChange}>
                                            <option value="">Seleccione una opción</option>
                                            <option value="5">Siempre </option>
                                            <option value="4">La mayoría de las veces sí</option>
                                            <option value="3">Algunas veces sí, algunas veces no </option>
                                            <option value="2">La mayoría de las veces no</option>
                                            <option value="1">Nunca	</option>
                                        </select>
                                        <label className='separatorQuestion'>{fourthQuestions[3]}</label>
                                    </div>
                                    <div className='input-field col s12 separator'>
                                        <select required name='question30' onChange={this.props.handleChange}>
                                            <option value="">Seleccione una opción</option>
                                            <option value="5">Siempre </option>
                                            <option value="4">La mayoría de las veces sí</option>
                                            <option value="3">Algunas veces sí, algunas veces no </option>
                                            <option value="2">La mayoría de las veces no</option>
                                            <option value="1">Nunca	</option>
                                        </select>
                                        <label>{fourthQuestions[4]}</label>
                                    </div>
                                    <div className='input-field col s12 separator'>
                                        <select required name='question31' onChange={this.props.handleChange}>
                                            <option value="">Seleccione una opción</option>
                                            <option value="1">Siempre </option>
                                            <option value="2">La mayoría de las veces sí</option>
                                            <option value="3">Algunas veces sí, algunas veces no </option>
                                            <option value="4">La mayoría de las veces no</option>
                                            <option value="5">Nunca	</option>
                                        </select>
                                        <label className='separatorQuestion'>{fourthQuestions[5]}</label>
                                    </div>
                                    <div className='input-field col s12 separator'>
                                        <select required name='question32' onChange={this.props.handleChange}>
                                            <option value="">Seleccione una opción</option>
                                            <option value="5">Siempre </option>
                                            <option value="4">La mayoría de las veces sí</option>
                                            <option value="3">Algunas veces sí, algunas veces no </option>
                                            <option value="2">La mayoría de las veces no</option>
                                            <option value="1">Nunca	</option>
                                        </select>
                                        <label className='separatorQuestion'>{fourthQuestions[6]}</label>
                                    </div>
                                    <div className='input-field col s12 separator'>
                                        <select required name='question33' onChange={this.props.handleChange}>
                                            <option value="">Seleccione una opción</option>
                                            <option value="5">Siempre </option>
                                            <option value="4">La mayoría de las veces sí</option>
                                            <option value="3">Algunas veces sí, algunas veces no </option>
                                            <option value="2">La mayoría de las veces no</option>
                                            <option value="1">Nunca	</option>
                                        </select>
                                        <label>{fourthQuestions[7]}</label>
                                    </div>
                                    <div className='input-field col s12 separator'>
                                        <select required name='question34' onChange={this.props.handleChange}>
                                            <option value="">Seleccione una opción</option>
                                            <option value="5">Siempre </option>
                                            <option value="4">La mayoría de las veces sí</option>
                                            <option value="3">Algunas veces sí, algunas veces no </option>
                                            <option value="2">La mayoría de las veces no</option>
                                            <option value="1">Nunca	</option>
                                        </select>
                                        <label>{fourthQuestions[8]}</label>
                                    </div>
                                    <div className='input-field col s12 separator'>
                                        <select required name='question35' onChange={this.props.handleChange}>
                                            <option value="">Seleccione una opción</option>
                                            <option value="5">Siempre </option>
                                            <option value="4">La mayoría de las veces sí</option>
                                            <option value="3">Algunas veces sí, algunas veces no </option>
                                            <option value="2">La mayoría de las veces no</option>
                                            <option value="1">Nunca	</option>
                                        </select>
                                        <label className='separatorQuestion'>{fourthQuestions[9]}</label>
                                    </div>
                                    <div className='input-field col s12 separator'>
                                        <select required name='question36' onChange={this.props.handleChange}>
                                            <option value="">Seleccione una opción</option>
                                            <option value="5">Siempre </option>
                                            <option value="4">La mayoría de las veces sí</option>
                                            <option value="3">Algunas veces sí, algunas veces no </option>
                                            <option value="2">La mayoría de las veces no</option>
                                            <option value="1">Nunca	</option>
                                        </select>
                                        <label>{fourthQuestions[10]}</label>
                                    </div>
                                    <div className='input-field col s12 separator'>
                                        <select required name='question37' onChange={this.props.handleChange}>
                                            <option value="">Seleccione una opción</option>
                                            <option value="5">Siempre </option>
                                            <option value="4">La mayoría de las veces sí</option>
                                            <option value="3">Algunas veces sí, algunas veces no </option>
                                            <option value="2">La mayoría de las veces no</option>
                                            <option value="1">Nunca	</option>
                                        </select>
                                        <label className='separatorQuestion'>{fourthQuestions[11]}</label>
                                    </div>
                                    <div className='input-field col s12 separator'>
                                        <select required name='question38' onChange={this.props.handleChange}>
                                            <option value="">Seleccione una opción</option>
                                            <option value="1">SI </option>
                                            <option value="0">NO</option>
                                        </select>
                                        <label className='separatorQuestion'>{fourthQuestions[12]}</label>
                                    </div>
                                    <div className='input-field col s12 separator'>
                                        {formation_plans}
                                    </div>
                                    <div className='input-field col s12 '>
                                        <p>
                                            <label>{fourthQuestions[14]}</label>
                                        </p>
                                        <p className='col s12'>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input required value='1' name="question39" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>SI</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='0' name="question39" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>NO</span>
                                            </label>
                                        </p>
                                    </div>
                                    <div className='input-field col s12 separator'>
                                        <select required name='question40' onChange={this.props.handleChange}>
                                            <option value="">Seleccione una opción</option>
                                            <option value="5">Siempre </option>
                                            <option value="4">La mayoría de las veces sí</option>
                                            <option value="3">Algunas veces sí, algunas veces no </option>
                                            <option value="2">La mayoría de las veces no</option>
                                            <option value="1">Nunca	</option>
                                        </select>
                                        <label>{fourthQuestions[15]}</label>
                                    </div>
                                    {/* Section 3.1 */}
                                    <span className='SubTitle col s12'>Responda las siguientes preguntas considerando la escala del 1 al 5; donde<br></br>
                                    <span className='descRange'> 5</span> Muy Satisfecho,
                                    <span className='descRange'> 4</span> Satisfecho,
                                    <span className='descRange'> 3</span> Ni Satisfecho, Ni Insatisfecho,
                                    <span className='descRange'> 2</span> Insatisfecho y  
                                    <span className='descRange'> 1</span> Muy Insatisfecho.</span>
                                    {/* Section 3.2 */}
                                    <span className='SubTitle col s12 separator labelQuestion'>{fourthQuestions[16]}</span>
                                    <div className='input-field col s12 separator'>
                                        <p>
                                            <label>{fourthQuestions[17]}</label>
                                        </p>
                                        <p className='col s12'>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input required value='5' name="question41_1" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>5. Muy Satisfecho</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='4' name="question41_1" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>4. Satisfecho</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='3' name="question41_1" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>3. Ni Satisfecho, Ni Insatisfecho</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='2' name="question41_1" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>2. Insatisfecho</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='1' name="question41_1" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>1. Muy Insatisfecho</span>
                                            </label> 
                                        </p>
                                    </div>
                                    <div className='input-field col s12'>
                                        <p>
                                            <label>{fourthQuestions[18]}</label>
                                        </p>
                                        <p className='col s12'>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input required value='5' name="question41_2" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>5. Muy Satisfecho</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='4' name="question41_2" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>4. Satisfecho</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='3' name="question41_2" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>3. Ni Satisfecho, Ni Insatisfecho</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='2' name="question41_2" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>2. Insatisfecho</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='1' name="question41_2" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>1. Muy Insatisfecho</span>
                                            </label> 
                                        </p>
                                    </div>
                                    <div className='input-field col s12'>
                                        <p>
                                            <label>{fourthQuestions[19]}</label>
                                        </p>
                                        <p className='col s12'>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input required value='5' name="question41_3" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>5. Muy Satisfecho</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='4' name="question41_3" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>4. Satisfecho</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='3' name="question41_3" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>3. Ni Satisfecho, Ni Insatisfecho</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='2' name="question41_3" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>2. Insatisfecho</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='1' name="question41_3" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>1. Muy Insatisfecho</span>
                                            </label> 
                                        </p>
                                    </div>
                                    <div className='input-field col s12'>
                                        <p>
                                            <label>{fourthQuestions[20]}</label>
                                        </p>
                                        <p className='col s12'>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input required value='5' name="question41_4" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>5. Muy Satisfecho</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='4' name="question41_4" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>4. Satisfecho</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='3' name="question41_4" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>3. Ni Satisfecho, Ni Insatisfecho</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='2' name="question41_4" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>2. Insatisfecho</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='1' name="question41_4" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>1. Muy Insatisfecho</span>
                                            </label> 
                                        </p>
                                    </div>
                                    <div className='input-field col s12'>
                                        <p>
                                            <label>{fourthQuestions[21]}</label>
                                        </p>
                                        <p className='col s12'>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input required value='5' name="question41_5" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>5. Muy Satisfecho</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='4' name="question41_5" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>4. Satisfecho</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='3' name="question41_5" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>3. Ni Satisfecho, Ni Insatisfecho</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='2' name="question41_5" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>2. Insatisfecho</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='1' name="question41_5" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>1. Muy Insatisfecho</span>
                                            </label> 
                                        </p>
                                    </div>
                                    <div className='input-field col s12'>
                                        <p>
                                            <label>{fourthQuestions[22]}</label>
                                        </p>
                                        <p className='col s12'>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input required value='5' name="question41_6" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>5. Muy Satisfecho</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='4' name="question41_6" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>4. Satisfecho</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='3' name="question41_6" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>3. Ni Satisfecho, Ni Insatisfecho</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='2' name="question41_6" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>2. Insatisfecho</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='1' name="question41_6" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>1. Muy Insatisfecho</span>
                                            </label> 
                                        </p>
                                    </div>
                                    <div className='input-field col s12'>
                                        <p>
                                            <label>{fourthQuestions[23]}</label>
                                        </p>
                                        <p className='col s12'>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input required value='5' name="question41_7" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>5. Muy Satisfecho</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='4' name="question41_7" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>4. Satisfecho</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='3' name="question41_7" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>3. Ni Satisfecho, Ni Insatisfecho</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='2' name="question41_7" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>2. Insatisfecho</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='1' name="question41_7" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>1. Muy Insatisfecho</span>
                                            </label> 
                                        </p>
                                    </div>
                                    <div className='input-field col s12'>
                                        <p>
                                            <label>{fourthQuestions[24]}</label>
                                        </p>
                                        <p className='col s12'>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input required value='5' name="question41_8" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>5. Muy Satisfecho</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='4' name="question41_8" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>4. Satisfecho</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='3' name="question41_8" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>3. Ni Satisfecho, Ni Insatisfecho</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='2' name="question41_8" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>2. Insatisfecho</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='1' name="question41_8" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>1. Muy Insatisfecho</span>
                                            </label> 
                                        </p>
                                    </div>
                                    <div className='input-field col s12'>
                                        <p>
                                            <label>{fourthQuestions[25]}</label>
                                        </p>
                                        <p className='col s12'>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input required value='5' name="question41_9" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>5. Muy Satisfecho</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='4' name="question41_9" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>4. Satisfecho</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='3' name="question41_9" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>3. Ni Satisfecho, Ni Insatisfecho</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='2' name="question41_9" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>2. Insatisfecho</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='1' name="question41_9" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>1. Muy Insatisfecho</span>
                                            </label> 
                                        </p>
                                    </div>
                                    <div className='input-field col s12'>
                                        <p>
                                            <label>{fourthQuestions[26]}</label>
                                        </p>
                                        <p className='col s12'>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input required value='5' name="question41_10" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>5. Muy Satisfecho</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='4' name="question41_10" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>4. Satisfecho</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='3' name="question41_10" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>3. Ni Satisfecho, Ni Insatisfecho</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='2' name="question41_10" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>2. Insatisfecho</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='1' name="question41_10" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>1. Muy Insatisfecho</span>
                                            </label> 
                                        </p>
                                    </div>
                                    <div className='input-field col s12'>
                                        <p>
                                            <label>{fourthQuestions[27]}</label>
                                        </p>
                                        <p className='col s12'>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input required value='5' name="question41_11" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>5. Muy Satisfecho</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='4' name="question41_11" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>4. Satisfecho</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='3' name="question41_11" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>3. Ni Satisfecho, Ni Insatisfecho</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='2' name="question41_11" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>2. Insatisfecho</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='1' name="question41_11" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>1. Muy Insatisfecho</span>
                                            </label> 
                                        </p>
                                    </div>
                                    <div className='input-field col s12'>
                                        <p>
                                            <label>{fourthQuestions[28]}</label>
                                        </p>
                                        <p className='col s12'>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input required value='5' name="question41_12" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>5. Muy Satisfecho</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='4' name="question41_12" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>4. Satisfecho</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='3' name="question41_12" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>3. Ni Satisfecho, Ni Insatisfecho</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='2' name="question41_12" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>2. Insatisfecho</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='1' name="question41_12" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>1. Muy Insatisfecho</span>
                                            </label> 
                                        </p>
                                    </div>
                                    <div className='input-field col s12'>
                                        <p>
                                            <label>{fourthQuestions[29]}</label>
                                        </p>
                                        <p className='col s12'>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input required value='5' name="question41_13" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>5. Muy Satisfecho</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='4' name="question41_13" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>4. Satisfecho</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='3' name="question41_13" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>3. Ni Satisfecho, Ni Insatisfecho</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='2' name="question41_13" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>2. Insatisfecho</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='1' name="question41_13" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>1. Muy Insatisfecho</span>
                                            </label> 
                                        </p>
                                    </div>
                                    <div className='input-field col s12'>
                                        <p>
                                            <label>{fourthQuestions[30]}</label>
                                        </p>
                                        <p className='col s12'>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input required value='5' name="question41_14" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>5. Muy Satisfecho</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='4' name="question41_14" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>4. Satisfecho</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='3' name="question41_14" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>3. Ni Satisfecho, Ni Insatisfecho</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='2' name="question41_14" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>2. Insatisfecho</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='1' name="question41_14" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>1. Muy Insatisfecho</span>
                                            </label> 
                                        </p>
                                    </div>
                                    <div className='input-field col s12'>
                                        <p>
                                            <label>{fourthQuestions[31]}</label>
                                        </p>
                                        <p className='col s12'>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input required value='5' name="question41_15" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>5. Muy Satisfecho</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='4' name="question41_15" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>4. Satisfecho</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='3' name="question41_15" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>3. Ni Satisfecho, Ni Insatisfecho</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='2' name="question41_15" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>2. Insatisfecho</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='1' name="question41_15" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>1. Muy Insatisfecho</span>
                                            </label> 
                                        </p>
                                    </div>
                                    <div className='input-field col s12'>
                                        <p>
                                            <label>{fourthQuestions[32]}</label>
                                        </p>
                                        <p className='col s12'>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input required value='5' name="question41_16" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>5. Muy Satisfecho</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='4' name="question41_16" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>4. Satisfecho</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='3' name="question41_16" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>3. Ni Satisfecho, Ni Insatisfecho</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='2' name="question41_16" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>2. Insatisfecho</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='1' name="question41_16" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>1. Muy Insatisfecho</span>
                                            </label> 
                                        </p>
                                    </div>
                                    <div className='input-field col s12'>
                                        <p>
                                            <label>{fourthQuestions[33]}</label>
                                        </p>
                                        <p className='col s12'>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input required value='5' name="question41_17" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>5. Muy Satisfecho</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='4' name="question41_17" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>4. Satisfecho</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='3' name="question41_17" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>3. Ni Satisfecho, Ni Insatisfecho</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='2' name="question41_17" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>2. Insatisfecho</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='1' name="question41_17" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>1. Muy Insatisfecho</span>
                                            </label> 
                                        </p>
                                    </div>
                                    <div className='input-field col s12'>
                                        <p>
                                            <label>{fourthQuestions[34]}</label>
                                        </p>
                                        <p className='col s12'>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input required value='5' name="question41_18" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>5. Muy Satisfecho</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='4' name="question41_18" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>4. Satisfecho</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='3' name="question41_18" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>3. Ni Satisfecho, Ni Insatisfecho</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='2' name="question41_18" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>2. Insatisfecho</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='1' name="question41_18" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>1. Muy Insatisfecho</span>
                                            </label> 
                                        </p>
                                    </div>
                                    <div className='input-field col s12'>
                                        <p>
                                            <label>{fourthQuestions[35]}</label>
                                        </p>
                                        <p className='col s12'>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input required value='5' name="question41_19" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>5. Muy Satisfecho</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='4' name="question41_19" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>4. Satisfecho</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='3' name="question41_19" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>3. Ni Satisfecho, Ni Insatisfecho</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='2' name="question41_19" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>2. Insatisfecho</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='1' name="question41_19" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>1. Muy Insatisfecho</span>
                                            </label> 
                                        </p>
                                    </div>
                                    <div className='input-field col s12'>
                                        <p>
                                            <label>{fourthQuestions[36]}</label>
                                        </p>
                                        <p className='col s12'>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input required value='5' name="question41_20" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>5. Muy Satisfecho</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='4' name="question41_20" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>4. Satisfecho</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='3' name="question41_20" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>3. Ni Satisfecho, Ni Insatisfecho</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='2' name="question41_20" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>2. Insatisfecho</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='1' name="question41_20" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>1. Muy Insatisfecho</span>
                                            </label> 
                                        </p>
                                    </div>
                                    <div className='input-field col s12'>
                                        <p>
                                            <label>{fourthQuestions[37]}</label>
                                        </p>
                                        <p className='col s12'>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input required value='5' name="question41_21" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>5. Muy Satisfecho</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='4' name="question41_21" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>4. Satisfecho</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='3' name="question41_21" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>3. Ni Satisfecho, Ni Insatisfecho</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='2' name="question41_21" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>2. Insatisfecho</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='1' name="question41_21" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>1. Muy Insatisfecho</span>
                                            </label> 
                                        </p>
                                    </div>
                                    <div className='input-field col s12'>
                                        <p>
                                            <label>{fourthQuestions[38]}</label>
                                        </p>
                                        <p className='col s12'>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input required value='5' name="question41_22" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>5. Muy Satisfecho</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='4' name="question41_22" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>4. Satisfecho</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='3' name="question41_22" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>3. Ni Satisfecho, Ni Insatisfecho</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='2' name="question41_22" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>2. Insatisfecho</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='1' name="question41_22" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>1. Muy Insatisfecho</span>
                                            </label> 
                                        </p>
                                    </div>
                                    <div className='input-field col s12'>
                                        <p>
                                            <label>{fourthQuestions[39]}</label>
                                        </p>
                                        <p className='col s12'>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input required value='5' name="question41_23" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>5. Muy Satisfecho</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='4' name="question41_23" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>4. Satisfecho</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='3' name="question41_23" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>3. Ni Satisfecho, Ni Insatisfecho</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='2' name="question41_23" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>2. Insatisfecho</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='1' name="question41_23" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>1. Muy Insatisfecho</span>
                                            </label> 
                                        </p>
                                    </div>
                                    <div className='input-field col s12'>
                                        <p>
                                            <label>{fourthQuestions[40]}</label>
                                        </p>
                                        <p className='col s12'>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input required value='5' name="question41_24" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>5. Muy Satisfecho</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='4' name="question41_24" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>4. Satisfecho</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='3' name="question41_24" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>3. Ni Satisfecho, Ni Insatisfecho</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='2' name="question41_24" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>2. Insatisfecho</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='1' name="question41_24" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>1. Muy Insatisfecho</span>
                                            </label> 
                                        </p>
                                    </div>
                                    <div className='input-field col s12'>
                                        <p>
                                            <label>{fourthQuestions[41]}</label>
                                        </p>
                                        <p className='col s12'>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input required value='5' name="question41_25" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>5. Muy Satisfecho</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='4' name="question41_25" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>4. Satisfecho</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='3' name="question41_25" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>3. Ni Satisfecho, Ni Insatisfecho</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='2' name="question41_25" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>2. Insatisfecho</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='1' name="question41_25" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>1. Muy Insatisfecho</span>
                                            </label> 
                                        </p>
                                    </div>
                                    <div className='input-field col s12'>
                                        <p>
                                            <label>{fourthQuestions[42]}</label>
                                        </p>
                                        <p className='col s12'>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input required value='5' name="question41_26" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>5. Muy Satisfecho</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='4' name="question41_26" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>4. Satisfecho</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='3' name="question41_26" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>3. Ni Satisfecho, Ni Insatisfecho</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='2' name="question41_26" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>2. Insatisfecho</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='1' name="question41_26" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>1. Muy Insatisfecho</span>
                                            </label> 
                                        </p>
                                    </div>
                                    <div className='input-field col s12'>
                                        <p>
                                            <label>{fourthQuestions[43]}</label>
                                        </p>
                                        <p className='col s12'>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input required value='5' name="question41_27_1_1" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>5. Muy Satisfecho</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='4' name="question41_27_1_1" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>4. Satisfecho</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='3' name="question41_27_1_1" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>3. Ni Satisfecho, Ni Insatisfecho</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='2' name="question41_27_1_1" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>2. Insatisfecho</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='1' name="question41_27_1_1" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>1. Muy Insatisfecho</span>
                                            </label> 
                                        </p>
                                    </div>
                                    <div className='input-field col s12'>
                                        <p>
                                            <label>{fourthQuestions[44]}</label>
                                        </p>
                                        <p className='col s12'>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input required value='5' name="question41_27_1_2" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>5. Muy Satisfecho</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='4' name="question41_27_1_2" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>4. Satisfecho</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='3' name="question41_27_1_2" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>3. Ni Satisfecho, Ni Insatisfecho</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='2' name="question41_27_1_2" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>2. Insatisfecho</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='1' name="question41_27_1_2" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>1. Muy Insatisfecho</span>
                                            </label> 
                                        </p>
                                    </div>
                                    <div className='input-field col s12'>
                                        <p>
                                            <label>{fourthQuestions[45]}</label>
                                        </p>
                                        <p className='col s12'>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input required value='5' name="question41_28" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>5. Muy Satisfecho</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='4' name="question41_28" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>4. Satisfecho</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='3' name="question41_28" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>3. Ni Satisfecho, Ni Insatisfecho</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='2' name="question41_28" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>2. Insatisfecho</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='1' name="question41_28" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>1. Muy Insatisfecho</span>
                                            </label> 
                                        </p>
                                    </div>
                                    {/* Section 3.2 */}
                                </div>
                                <button onClick={this.validateForm4} className="btn waves-effect waves-light bg-btn" type="submit" name="action">
                                    Continuar
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        )
    }
}
export default FourthSection