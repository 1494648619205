import React,{Component} from 'react'
import './css/categories.css'
class Categories extends Component{
    // Proceed to next step
    continue = (event)=> {
        event.preventDefault();
        this.props.nextStep();
    }
    render(){
        return(
            <div className='container'>
                <h4 className='categoriesTitle'>Categorías de Análisis</h4>
                <p className='instructionParagraph'>
                <i className="material-icons left">nature</i>
                <span className='categorieSubTitle'> Bienestar Ambiental </span>: Percepción sobre  condiciones externas que posiblemente influyen en su desempeño como docente, tales como: infraestructura; dotaciones escolares y de tecnología; disponibilidad de recursos de enseñanza/aprendizaje; clima laboral (valores) e implementación de prácticas profesionales; seguridad escolar y salud física-ocupacional.    
                </p>
                <p className='instructionParagraph'>
                <i className="material-icons left">accessibility</i>
                <span className='categorieSubTitle'>Bienestar Subjetivo</span>: Percepción frente a la identificación de capacidades desde el <b><i>saber ser </i></b>y el <b><i>saber hacer</i></b>.
                </p>
                <p className='instructionParagraph'>
                <i className="material-icons left">group</i>
                <span className='categorieSubTitle'>Bienestar Interaccionista</span>: Percepción de las relaciones entre factores ambientales objetivos y subjetivos.  En esta categoría se aborda la capacidad de respuesta docente respecto a las condiciones laborales dadas y de relacionamiento con los miembros de la comunidad educativa.
                </p>
                <button onClick={this.continue} className="btn waves-effect waves-light bg-btn bg-btnPilot" type="submit" name="action">
                    Continuar
                </button>
            </div>
        )
    }
}

export default Categories