import React,{Component} from 'react'
import M from 'materialize-css/dist/js/materialize.min.js'
import './css/formGeneralDetails.css'
class FormGeneralDetails extends Component{
    componentDidMount() {
        M.AutoInit();
    }
    // Proceed to next step
    continue = (event)=> {
        event.preventDefault();
        this.props.nextStep();
    }
    // Validate Message
    validateForm = ()=>{
        if(!this.props.department){
            alert('Selecciona un departamento por favor')
        }else if(!this.props.department_ei){
            alert('Seleccione el Departamento donde se ubica el Establecimiento Educativo por favor')
        }
    }
    municipalityFromDepartment = (event)=>{
        event.preventDefault()
        this.props.getMunicipalityListFromDepartments()
    }
    render(){
        // const MunicipalityFromDepartments = this.props.municipality_data.filter(item => item.deparment_code === this.props.department)
        return (
        <div className='container'>
            <div className='row'>
                <div className='input-field col s12'>
                    <h6 className='fGeneralDetailsSubTitle'>Objetivo: Conocer a través de un diagnóstico institucional las necesidades y requerimientos en temas de Bienestar Laboral Docente.</h6>
                </div>
                <div className='col s12'>
                    <div className='card'>
                        <div className='card-content'>
                            <span className='fGeneralDetailsTitle'>Información General</span>
                            <div className='row'>
                                <form onSubmit={this.continue}>
                                    <div className='input-field col s12'>
                                        <select required name='department' onChange={this.props.handleChange}>
                                            <option value="">Seleccione el Departamento donde Nació</option>
                                            {this.props.department_data.map((item,i)=>{
                                                return(
                                                    <option key={i} value={item.department_code}>{item.department}</option>
                                                )
                                            })}
                                        </select>
                                        <label>Departamento donde Nació</label>                                    
                                    </div>
                                    <div className='input-field col s12'>
                                        <select required name='department_ei' onChange={this.props.handleChange}>
                                            <option value="">Seleccione el Departamento se ubica el Establecimiento Educativo</option>
                                            {this.props.department_data_ie.map((item,i)=>{
                                            return(
                                                <option key={i} value={item.department_code}>{item.department}</option>
                                            )
                                        })}
                                        </select>
                                        <label>Departamento donde se ubica el Establecimiento Educativo</label>
                                    </div>
                                    <button onClick={this.validateForm} className="btn waves-effect waves-light bg-btn bg-btnPilot" type="submit" name="action">
                                        Continuar
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        )
    }
}
export default FormGeneralDetails