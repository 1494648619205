import React,{Component} from 'react'
import M from 'materialize-css/dist/js/materialize.min.js'

import './css/fifthSection.css'
class LastSection extends Component{
    componentDidMount() {
        M.AutoInit();
    }
    // Proceed to send form
    sendAll = (event)=> {
        event.preventDefault();
        this.props.nextStep();
    }
    
render(){
    return (
        <div className='container'>
            <div className='row'>
                <div className='input-field col s12'>
                </div>
                <div className='col s12'>
                    <div className='card'>
                        <span className='fifthSectionBoxTitle col s12 fifthSectionBox'>Sección Final</span>
                        <div className='card-content'>
                            <form onSubmit={this.sendAll}>
                                <div className='row col s12'>
                                    <h5>Gracias por su participación, su encuesta fue diligenciada  </h5>
                                </div>
                                <button className="btn waves-effect waves-light bg-btn bg-btnPilot" type="submit" name="action">
                                    Terminar Encuesta
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        )
    }
}
export default LastSection