import React,{Component} from 'react'
import M from 'materialize-css/dist/js/materialize.min.js'
import './css/formGeneralDetails.css'
class FormGeneralDetailsMunicipality extends Component{
    componentDidMount() {
        M.AutoInit();
    }
    // Proceed to next step
    continue = (event)=> {
        event.preventDefault();
        this.props.createForm();
    }
    // Validate Message
    validateForm = ()=>{
        if(!this.props.municipality){
            alert('Selecciona un municipio por favor')
        } else if(!this.props.municipality_ei){
            alert('Seleccione el Municipio donde se ubica el Establecimiento Educativo por favor')
        }
    }
    // municipalityFromDepartment = (event)=>{
    //     event.preventDefault()
    //     this.props.getMunicipalityListFromDepartments()
    // }
    render(){
        const MunicipalityFromDepartmentsIE = this.props.municipality_data_ie.filter(item => item.deparment_code === this.props.department_ei)
        console.log(MunicipalityFromDepartmentsIE)
        const MunicipalityFromDepartments = this.props.municipality_data.filter(item => item.deparment_code === this.props.department)
        console.log(MunicipalityFromDepartments)
        return (
        <div className='container'>
            <div className='row'>
                <div className='input-field col s12'>
                    <h6 className='fGeneralDetailsSubTitle'><b>Objetivo:</b> Conocer a través de un diagnóstico institucional las necesidades y requerimientos en temas de Bienestar Laboral Docente.</h6>
                </div>
                <div className='col s12'>
                    <div className='card'>
                        <div className='card-content'>
                            <span className='fGeneralDetailsTitle'>Información General</span>
                            <div className='row'>
                                <form onSubmit={this.continue}>
                                    <div className='input-field col s12'>
                                        <select required name='municipality' onChange={this.props.handleChange}>
                                            <option value="">Seleccione el Municipio donde nació</option>
                                            {MunicipalityFromDepartments.map((item,i)=>{
                                                return(
                                                    <option key={i} value={item.municipality_code}>{item.municipality_name}</option>
                                                )
                                            })}
                                        </select>
                                        <label>Municipio donde Nació</label>
                                    </div>
                                    <div className='input-field col s12'>
                                        <select required name='municipality_ei' onChange={this.props.handleChange}>
                                            <option value="">Seleccione el Municipio donde se ubica el Establecimiento Educativo</option>
                                            {MunicipalityFromDepartmentsIE.map((item,i)=>{
                                            return(
                                                <option key={i} value={item.municipality_code}>{item.municipality_name}</option>
                                            )
                                        })}
                                        </select>
                                        <label>Municipio donde se ubica el Establecimiento Educativo</label>
                                    </div>
                                    <div className='input-field col s6'>
                                    </div>
                                    <button onClick={this.validateForm} className="btn waves-effect waves-light bg-btn bg-btnPilot" type="submit" name="action">
                                        Continuar
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        )
    }
}
export default FormGeneralDetailsMunicipality