import React,{Component} from 'react'
import M from 'materialize-css/dist/js/materialize.min.js'

import './css/fifthSection.css'
class AlreadySection extends Component{
    componentDidMount() {
        M.AutoInit();
    }
    // Proceed to send form
    goBackStep = (event)=> {
        event.preventDefault();
        this.props.goBackStep();
    }
    
render(){
    return (
    <div className='container'>
        <div className='row'>
            <div className='card col s12'>
                <h4 className='instructionsTitlePilot'><i>Apreciado(a) Educador(a)</i></h4>
                <p className='instructionsubTitlePilot'>Cordial saludo,</p>
                <p className='instructionParagraphPilot'>
                    <i>
                        La encuesta sobre Bienestar Laboral Docente finalizó. Gracias por sus aportes. 
                    </i>
                </p>
            </div>
        </div>
    </div>
        )
    }
}
export default AlreadySection