import React,{Component} from 'react'
import './css/instructions.css'
class Instructions extends Component{
    // Proceed to next step
    continue = (event)=> {
        event.preventDefault();
        this.props.nextStep();
    }
    render(){
        return(
            <div className='container'>
                <h4 className='instructionsTitle'>Instructivo de aplicación</h4>
                <p className='instructionParagraph'>
                El presente instrumento de medición será aplicado exclusivamente
                a Docentes y Directivos 
                Docentes de Preescolar, Básica y Media, con el propósito de recoger 
                información relevante sobre la percepción del Bienestar Laboral, 
                entendido como el nivel de satisfacción frente a: 
                <br></br><br></br>
                <b> - Las condiciones externas - ambientales</b>: como la 
                infraestructura; las dotaciones escolares; la tecnología; el 
                clima laboral en cuanto a valores organizacionales; 
                capacitaciones; seguridad y salud física-ocupacional;
                <br></br><br></br>
                <b> - Las capacidades subjetivas del saber ser y del 
                saber hacer</b>: incluyen la autonomía; motivación al logro; la 
                afiliación; pertenencia; dignidad; entendimiento; iniciativa; 
                satisfacción laboral; retroalimentación profesional; metas y 
                valores personales;<b><br></br><br></br>
                - Valoración de las relaciones
                entre factores ambientales y subjetivos – interaccionistas -: </b>  
                involucra la capacidad de respuesta docente y las 
                relaciones interpersonales con la comunidad educativa.
                <br></br><br></br>
                A continuación, encontrará un cuestionario con preguntas agrupadas 
                en tres categorías de Bienestar: <b>Ambiental</b>, <b>Subjetivo </b>
                e <b>Interaccionista</b>. 
                Le recomendamos contar con los elementos tecnológicos y logísticos 
                necesarios para evitar algún tipo de interrupción. 
                <br></br><br></br>
                Agradecemos su honestidad al responder. Recuerde que los datos 
                suministrados en el cuestionario serán utilizados exclusivamente con 
                fines investigativos y académicos.
                <br></br><br></br>
                Por favor, lea atentamente cada enunciado y las opciones de respuesta 
                antes de contestar las preguntas.
                <br></br><br></br>
                Le recomendamos que la realización de esta encuesta sea desde un computador
                usando navegadores como Google Chrome o Mozilla Firefox y no sobre 
                dispositivos Móviles y/o Tablets.
                </p>
                <button onClick={this.continue} className="btn waves-effect waves-light bg-btn bg-btnPilot" type="submit" name="action">Continuar
                </button>
            </div>
        )
    }
}

export default Instructions