import React, {Component} from 'react'
import './css/home.css'
import FirstIndications from '../components/firstIndications'
import Indications from '../components/indications'
import Instructions from '../components/instructions'
import Categories from '../components/categories'
import FormGeneralDetails from '../components/formGeneralDetails'
import FormGeneralDetailsMunicipality from '../components/formGeneralDetailsMunicipality'
import FirstSection from '../components/firstSection'
import SecondSection from '../components/secondSection'
import ThirdSection from '../components/thirdSection'
import FourthSection from '../components/fourthSection'
import SubjectiveSection from '../components/SubjectiveWellBeingSection'
import FifthSection from '../components/fifthSection'
import SixthSection from '../components/sixthSection'
import SeventhSection from '../components/seventhSection'
import LastSection from '../components/lastSection'
import AlreadySection from '../components/alreadySection'
import Progress from '../components/progress'
class Home extends Component{
    constructor(props){
        super(props)
        this.state={
            step:15,
            stepProgess:0,
            ages_data:[],
            childrens_data:[],
            students_grups_data:[],
            years_of_experience_data:[],
            category_in_the_ranks_data:[],
            department_data:[],
            department_data_ie:[],
            municipality_data:[],
            municipality_data_ie:[],
            identity_document_data:[],
            identity_document:'',
            email:'',
            department:'',
            municipality:'',
            // First Section
            full_name:'',
            age:'',
            contact_phone:'',
            sex:'',
            gender:'',
            civil_status:'',
            number_of_childrens:'',
            occupational_disease:'',
            education_level:'',
            situation1:'',
            situation2:'',
            situation3:'',
            situation4:'',
            situation5:'',
            situation6:'',
            situation7:'',
            situation8:'',
            property:'',
            ethnic_group:'',
            department_ei:'',
            municipality_ei:'',
            name_ei:'',
            zone:'',
            student_volume:'',
            student_group:'',
            student_in_charge:'',
            bonding:'',
            academic_assignment:'00',
            years_of_experience:'',
            category_in_the_ranks:'',
            statute:'',
            // First Section
            // Second Section
            question1:'',
            question2:'',
            question3:'',
            question4:'',
            question5:'',
            question6:'',
            question7:'',
            question8:'',
            question9:'',
            question10:'',
            question11:'',
            question12:'',
            question13:'',
            question14:'',
            question15:'',
            question16:'',
            // Second Section
            // Third Section
            question17:'',
            question18:'',
            question19:'',
            question20:'',
            question21:'',
            question22:'',
            question23:'',
            question24:'',
            question25_1:'',
            question25_2:'',
            question25_3:'',
            question25_4:'',
            question25_5:'',
            question25_6:'',
            question25_7:'',
            question25_8:'',
            question25_9:'',
            question25_10:'',
            question25_11:'',
            question25_12:'',
            question25_13:'',
            question25_14:'',
            question25_15:'',
            question25_16:'',
            question25_17:'',
            question25_18:'',
            question25_19:'',
            question25_20:'',
            // Third Section
            // Fourth Section
            question26:'',
            question27:'',
            question28:'',
            question29:'',
            question30:'',
            question31:'',
            question32:'',
            question33:'',
            question34:'',
            question35:'',
            question36:'',
            question37:'',
            question38:'',
            formation_plans:'00',
            question39:'',
            question40:'',
            question41_1:'',
            question41_2:'',
            question41_3:'',
            question41_4:'',
            question41_5:'',
            question41_6:'',
            question41_7:'',
            question41_8:'',
            question41_9:'',
            question41_10:'',
            question41_11:'',
            question41_12:'',
            question41_13:'',
            question41_14:'',
            question41_15:'',
            question41_16:'',
            question41_17:'',
            question41_18:'',
            question41_19:'',
            question41_20:'',
            question41_21:'',
            question41_22:'',
            question41_23:'',
            question41_24:'',
            question41_25:'',
            question41_26:'',
            question41_27_1_1:'',
            question41_27_1_2:'',
            question41_28:'',
            // Fourth Section
            // Fifth Section
            question42:'',
            question43:'',
            // Fifth Section
            // Sixth Section
            question44:'',
            question45:'',
            question46:'',
            question47:'',
            question48:'',
            question49:'',
            question50:'',
            question51:'',
            question52:'',
            question53:'',
            // Sixth Section
            // Seventh Section
            question54:'',
            question55:'',
            question56:'',
            question57:'',
            question58:'',
            question59:'',
            question60:'',
            question61:'',
            question62:'',
            // Seventh Section
            // Eight Section
            question63:'',
            extra_game_question63_1:'00',
            extra_game_question63_2:'00',
            extra_game_question63_3:'00',
            extra_game_question63_4:'00',
            extra_game_question63_5:'00',
            extra_game_question63_6:'00',
            extra_game_question63_7:'00',
            extra_game_question63_8:'00',
            extra_game_question63_9:'00',
            extra_game_question63_10:'00',
            extra_game_question63_11:'00',
            extra_game_question63_12:'00',
            extra_game_question63_13:'00',
            extra_game_question63_14:'00',
            extra_game_question63_15:'00',
            extra_game_question63_16:'00',
            question64:'',
            question65:'',
            question66:'',
            question67:'',
            question68:'',
            question69:'',
            question70:'',
            question71:'',
            question72:'',
            question73:'',
            question74:'',
            extra_question74_1:'00',
            extra_question74_2:'00',
            extra_question74_3:'00',
            extra_question74_4:'00',
            extra_question74_5:'00',
            extra_question74_6:'00',
            extra_question74_7:'00',
            extra_question74_level:'00',
            // Eight Section
            hour:new Date().toLocaleTimeString(),
            date:new Date().toLocaleDateString(),
        }
    }
    //Create teacher Form
    createForm = ()=>{
        console.log("Estado para enviar:", JSON.stringify(this.state))
        // let API_URL_LOCAL = 'http://localhost:9595/'
        let API_URL = 'https://encuestabienestarmen.pedagogica.edu.co/services/'
        const dataForm = {
        step:this.state.step + 1,
        identity_document:this.state.identity_document,
        department:this.state.department,
        municipality:this.state.municipality,
        department_ei:this.state.department_ei,
        municipality_ei:this.state.municipality_ei
    }
        fetch(`${API_URL}form/create_teacher`, {
            method: 'POST',
            body: JSON.stringify(dataForm),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(data => {
            const {step} = this.state
            this.setState({
                step:step + 1
            })
            console.log(data);
            })
            .catch(err => console.error(err));
    }
    //Create teacher Form section 1
    createFormSection1 = ()=>{
        console.log("Estado para enviar:", JSON.stringify(this.state))
        // let API_URL_LOCAL = 'http://localhost:9595/'
        let API_URL = 'https://encuestabienestarmen.pedagogica.edu.co/services/'
        const dataForm = {
        step:this.state.step + 1,
        identity_document:this.state.identity_document,
        full_name:this.state.full_name,
        email:this.state.email,
        age:this.state.age,
        contact_phone:this.state.contact_phone,
        sex:this.state.sex,
        gender:this.state.gender,
        civil_status:this.state.civil_status,
        number_of_childrens:this.state.number_of_childrens,
        occupational_disease:this.state.occupational_disease,
        education_level:this.state.education_level,
        situation1:this.state.situation1,
        situation2:this.state.situation2,
        situation3:this.state.situation3,
        situation4:this.state.situation4,
        situation5:this.state.situation5,
        situation6:this.state.situation6,
        situation7:this.state.situation7,
        situation8:this.state.situation8,
        property:this.state.property,
        ethnic_group:this.state.ethnic_group,
        name_ei:this.state.name_ei,
        zone:this.state.zone,
        student_volume:this.state.student_volume,
        student_group:this.state.student_group,
        student_in_charge:this.state.student_in_charge,
        bonding:this.state.bonding,
        academic_assignment:this.state.academic_assignment,
        years_of_experience:this.state.years_of_experience,
        category_in_the_ranks:this.state.category_in_the_ranks,
        statute:this.state.statute,
        hour:this.state.hour,
        date:this.state.date
    }
        fetch(`${API_URL}form/section_one`, {
            method: 'POST',
            body: JSON.stringify(dataForm),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(data => {
            const {step} = this.state
            this.setState({
                step:step + 1
            })
            console.log(data);
            })
            .catch(err => console.error(err));
    }
    //Create teacher Form section 2
    createFormSection2 = ()=>{
        console.log("Estado para enviar:", JSON.stringify(this.state))
        // let API_URL_LOCAL = 'http://localhost:9595/'
        let API_URL = 'https://encuestabienestarmen.pedagogica.edu.co/services/'
        const dataForm = {
        step:this.state.step + 1,
        identity_document:this.state.identity_document,
        question1:this.state.question1,
        question2:this.state.question2,
        question3:this.state.question3,
        question4:this.state.question4,
        question5:this.state.question5,
        question6:this.state.question6,
        question7:this.state.question7,
        question8:this.state.question8,
        question9:this.state.question9,
        question10:this.state.question10,
        question11:this.state.question11,
        question12:this.state.question12,
        question13:this.state.question13,
        question14:this.state.question14,
        question15:this.state.question15,
        question16:this.state.question16,
        hour:this.state.hour,
        date:this.state.date
    }
        fetch(`${API_URL}form/section_two`, {
            method: 'POST',
            body: JSON.stringify(dataForm),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(data => {
            const {step} = this.state
            this.setState({
                step:step + 1
            })
            console.log(data);
            })
            .catch(err => console.error(err));
    }
    //Create teacher Form section 3
    createFormSection3 = ()=>{
        console.log("Estado para enviar:", JSON.stringify(this.state))
        // let API_URL_LOCAL = 'http://localhost:9595/'
        let API_URL = 'https://encuestabienestarmen.pedagogica.edu.co/services/'
        const dataForm = {
        step:this.state.step + 1,
        identity_document:this.state.identity_document,
        question17:this.state.question17,
        question18:this.state.question18,
        question19:this.state.question19,
        question20:this.state.question20,
        question21:this.state.question21,
        question22:this.state.question22,
        question23:this.state.question23,
        question24:this.state.question24,
        question25_1:this.state.question25_1,
        question25_2:this.state.question25_2,
        question25_3:this.state.question25_3,
        question25_4:this.state.question25_4,
        question25_5:this.state.question25_5,
        question25_6:this.state.question25_6,
        question25_7:this.state.question25_7,
        question25_8:this.state.question25_8,
        question25_9:this.state.question25_9,
        question25_10:this.state.question25_10,
        question25_11:this.state.question25_11,
        question25_12:this.state.question25_12,
        question25_13:this.state.question25_13,
        question25_14:this.state.question25_14,
        question25_15:this.state.question25_15,
        question25_16:this.state.question25_16,
        question25_17:this.state.question25_17,
        question25_18:this.state.question25_18,
        question25_19:this.state.question25_19,
        question25_20:this.state.question25_20,
        hour:this.state.hour,
        date:this.state.date
    }
        fetch(`${API_URL}form/section_three`, {
            method: 'POST',
            body: JSON.stringify(dataForm),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(data => {
            const {step} = this.state
            this.setState({
                step:step + 1
            })
            console.log(data);
            })
            .catch(err => console.error(err));
    }
    //Create teacher Form section 4
    createFormSection4 = ()=>{
        console.log("Estado para enviar:", JSON.stringify(this.state))
        // let API_URL_LOCAL = 'http://localhost:9595/'
        let API_URL = 'https://encuestabienestarmen.pedagogica.edu.co/services/'
        const dataForm = {
        step:this.state.step + 1,
        identity_document:this.state.identity_document,
        question26:this.state.question26,
        question27:this.state.question27,
        question28:this.state.question28,
        question29:this.state.question29,
        question30:this.state.question30,
        question31:this.state.question31,
        question32:this.state.question32,
        question33:this.state.question33,
        question34:this.state.question34,
        question35:this.state.question35,
        question36:this.state.question36,
        question37:this.state.question37,
        question38:this.state.question38,
        formation_plans:this.state.formation_plans,
        question39:this.state.question39,
        question40:this.state.question40,
        question41_1:this.state.question41_1,
        question41_2:this.state.question41_2,
        question41_3:this.state.question41_3,
        question41_4:this.state.question41_4,
        question41_5:this.state.question41_5,
        question41_6:this.state.question41_6,
        question41_7:this.state.question41_7,
        question41_8:this.state.question41_8,
        question41_9:this.state.question41_9,
        question41_10:this.state.question41_10,
        question41_11:this.state.question41_11,
        question41_12:this.state.question41_12,
        question41_13:this.state.question41_13,
        question41_14:this.state.question41_14,
        question41_15:this.state.question41_15,
        question41_16:this.state.question41_16,
        question41_17:this.state.question41_17,
        question41_18:this.state.question41_18,
        question41_19:this.state.question41_19,
        question41_20:this.state.question41_20,
        question41_21:this.state.question41_21,
        question41_22:this.state.question41_22,
        question41_23:this.state.question41_23,
        question41_24:this.state.question41_24,
        question41_25:this.state.question41_25,
        question41_26:this.state.question41_26,
        question41_27_1_1:this.state.question41_27_1_1,
        question41_27_1_2:this.state.question41_27_1_2,
        question41_28:this.state.question41_28,
        hour:this.state.hour,
        date:this.state.date
    }
        fetch(`${API_URL}form/section_four`, {
            method: 'POST',
            body: JSON.stringify(dataForm),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(data => {
            const {step} = this.state
            this.setState({
                step:step + 1
            })
            console.log(data);
            })
            .catch(err => console.error(err));
    }
    //Create teacher Form section 5
    createFormSection5 = ()=>{
        console.log("Estado para enviar:", JSON.stringify(this.state))
        // let API_URL_LOCAL = 'http://localhost:9595/'
        let API_URL = 'https://encuestabienestarmen.pedagogica.edu.co/services/'
        const dataForm = {
        step:this.state.step + 1,
        identity_document:this.state.identity_document,
        question42:this.state.question42,
        question43:this.state.question43,
        hour:this.state.hour,
        date:this.state.date
    }
        fetch(`${API_URL}form/section_five`, {
            method: 'POST',
            body: JSON.stringify(dataForm),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(data => {
            const {step} = this.state
            this.setState({
                step:step + 1
            })
            console.log(data);
            })
            .catch(err => console.error(err));
    }
    //Create teacher Form section 6
    createFormSection6 = ()=>{
        console.log("Estado para enviar:", JSON.stringify(this.state))
        // let API_URL_LOCAL = 'http://localhost:9595/'
        let API_URL = 'https://encuestabienestarmen.pedagogica.edu.co/services/'
        const dataForm = {
        step:this.state.step + 1,
        identity_document:this.state.identity_document,
        question44:this.state.question44,
        question45:this.state.question45,
        question46:this.state.question46,
        question47:this.state.question47,
        question48:this.state.question48,
        question49:this.state.question49,
        question50:this.state.question50,
        question51:this.state.question51,
        question52:this.state.question52,
        question53:this.state.question53,
        hour:this.state.hour,
        date:this.state.date
    }
        fetch(`${API_URL}form/section_six`, {
            method: 'POST',
            body: JSON.stringify(dataForm),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(data => {
            const {step} = this.state
            this.setState({
                step:step + 1
            })
            console.log(data);
            })
            .catch(err => console.error(err));
    }
    //Create teacher Form section 7
    createFormSection7 = ()=>{
        console.log("Estado para enviar:", JSON.stringify(this.state))
        // let API_URL_LOCAL = 'http://localhost:9595/'
        let API_URL = 'https://encuestabienestarmen.pedagogica.edu.co/services/'
        const dataForm = {
        step:this.state.step + 1,
        identity_document:this.state.identity_document,
        question54:this.state.question54,
        question55:this.state.question55,
        question56:this.state.question56,
        question57:this.state.question57,
        question58:this.state.question58,
        question59:this.state.question59,
        question60:this.state.question60,
        question61:this.state.question61,
        question62:this.state.question62,
        hour:this.state.hour,
        date:this.state.date
    }
        fetch(`${API_URL}form/section_seven`, {
            method: 'POST',
            body: JSON.stringify(dataForm),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(data => {
            const {step} = this.state
            this.setState({
                step:step + 1
            })
            console.log(data);
            })
            .catch(err => console.error(err));
    }
    //Create teacher Form section 8
    createFormSection8 = ()=>{
        console.log("Estado para enviar:", JSON.stringify(this.state))
        // let API_URL_LOCAL = 'http://localhost:9595/'
        let API_URL = 'https://encuestabienestarmen.pedagogica.edu.co/services/'
        const dataForm = {
        step:this.state.step + 1,
        identity_document:this.state.identity_document,
        question63:this.state.question63,
        extra_game_question63_1:this.state.extra_game_question63_1,
        extra_game_question63_2:this.state.extra_game_question63_2,
        extra_game_question63_3:this.state.extra_game_question63_3,
        extra_game_question63_4:this.state.extra_game_question63_4,
        extra_game_question63_5:this.state.extra_game_question63_5,
        extra_game_question63_6:this.state.extra_game_question63_6,
        extra_game_question63_7:this.state.extra_game_question63_7,
        extra_game_question63_8:this.state.extra_game_question63_8,
        extra_game_question63_9:this.state.extra_game_question63_9,
        extra_game_question63_10:this.state.extra_game_question63_10,
        extra_game_question63_11:this.state.extra_game_question63_11,
        extra_game_question63_12:this.state.extra_game_question63_12,
        extra_game_question63_13:this.state.extra_game_question63_13,
        extra_game_question63_14:this.state.extra_game_question63_14,
        extra_game_question63_15:this.state.extra_game_question63_15,
        extra_game_question63_16:this.state.extra_game_question63_16,
        question64:this.state.question64,
        question65:this.state.question65,
        question66:this.state.question66,
        question67:this.state.question67,
        question68:this.state.question68,
        question69:this.state.question69,
        question70:this.state.question70,
        question71:this.state.question71,
        question72:this.state.question72,
        question73:this.state.question73,
        question74:this.state.question74,
        extra_question74_1:this.state.extra_question74_1,
        extra_question74_2:this.state.extra_question74_2,
        extra_question74_3:this.state.extra_question74_3,
        extra_question74_4:this.state.extra_question74_4,
        extra_question74_5:this.state.extra_question74_5,
        extra_question74_6:this.state.extra_question74_6,
        extra_question74_7:this.state.extra_question74_7,
        extra_question74_level:this.state.extra_question74_level,
        hour:this.state.hour,
        date:this.state.date
    }
        fetch(`${API_URL}form/section_eight`, {
            method: 'POST',
            body: JSON.stringify(dataForm),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(data => {
            const {step} = this.state
            this.setState({
                step:step + 1
            })
            console.log(data);
            })
            .catch(err => console.error(err));
    }
    // Validate Teachers
    validateID = ()=>{
        let stateDocument = parseInt(this.state.identity_document)
        let arrayIdentification = this.state.identity_document_data.find((item)=>{
            return item.identity_document === stateDocument
        })
        if(!arrayIdentification){
            const {step,stepProgess} = this.state
            this.setState({
                step:step + 1,
                stepProgess: stepProgess + 7.2
            })
        } else if(arrayIdentification.step === 15){
            this.setState({
                step:15
            })
        } else {
            const {stepProgess} = this.state
            this.setState({
                step:arrayIdentification.step,
                stepProgess: stepProgess + 7.2
            })
        }
    }
    //Send Form
    
    componentDidMount(){
        this.getAgesList()
        this.getChildrensList()
        this.getStudentsGroupsList()
        this.getYearsOfExperienceList()
        this.getDeparmentList()
        this.getDeparmentIEList()
        this.getMunicipalityList()
        this.getMunicipalityIEList()
        this.getCategoryInTheRanksList()
        this.getIdentityDocumentData()
    }
    componentDidUpdate(prevProps, prevState){
    }
    // Municipality -----------------------------------------------------------
    getMunicipalityList = async()=>{
        // let API_URL_LOCAL = 'http://localhost:9595/'
        let API_URL = 'https://encuestabienestarmen.pedagogica.edu.co/services/'
        try{
            let response = await fetch(`${API_URL}form/municipality`)
            let data = await response.json()
            this.setState({
                municipality_data:data
            })
        } catch(error){
            console.log(error)
        }
    }
    getMunicipalityIEList = async()=>{
        // let API_URL_LOCAL = 'http://localhost:9595/'
        let API_URL = 'https://encuestabienestarmen.pedagogica.edu.co/services/'
        try{
            let response = await fetch(`${API_URL}form/municipality`)
            let data = await response.json()
            this.setState({
                municipality_data_ie:data
            })
        } catch(error){
            console.log(error)
        }
    }
    // Municipality -----------------------------------------------------------
    // Department -------------------------------------------------------------
    getDeparmentList = async()=>{
        let API_URL = 'https://encuestabienestarmen.pedagogica.edu.co/services/'
        try{
            let response = await fetch(`${API_URL}form/department`)
            let data = await response.json()
            this.setState({
                department_data:data
            })
        } catch(error){
            console.log(error)
        }
    }
    getDeparmentIEList = async()=>{
        let API_URL = 'https://encuestabienestarmen.pedagogica.edu.co/services/'
        try{
            let response = await fetch(`${API_URL}form/department`)
            let data = await response.json()
            this.setState({
                department_data_ie:data
            })
        } catch(error){
            console.log(error)
        }
    }
    // Department -------------------------------------------------------------
    getAgesList = async()=>{
        // let API_URL_LOCAL = 'http://localhost:9595/'
        let API_URL = 'https://encuestabienestarmen.pedagogica.edu.co/services/'
        try{
            let response = await fetch(`${API_URL}form/ages`)
            let data = await response.json()
            this.setState({
                ages_data:data
            })
        } catch(error){
            console.log(error)
        }
    }
    getChildrensList = async()=>{
        // let API_URL_LOCAL = 'http://localhost:9595/'
        let API_URL = 'https://encuestabienestarmen.pedagogica.edu.co/services/'
        try{
            let response = await fetch(`${API_URL}form/childrens`)
            let data = await response.json()
            this.setState({
                childrens_data:data
            })
        } catch(error){
            console.log(error)
        }
    }
    getStudentsGroupsList = async()=>{
        // let API_URL_LOCAL = 'http://localhost:9595/'
        let API_URL = 'https://encuestabienestarmen.pedagogica.edu.co/services/'
        try{
            let response = await fetch(`${API_URL}form/students_groups`)
            let data = await response.json()
            this.setState({
                students_groups_data:data
            })
        } catch(error){
            console.log(error)
        }
    }
    getYearsOfExperienceList = async()=>{
        // let API_URL_LOCAL = 'http://localhost:9595/'
        let API_URL = 'https://encuestabienestarmen.pedagogica.edu.co/services/'
        try{
            let response = await fetch(`${API_URL}form/years_of_experience`)
            let data = await response.json()
            this.setState({
                years_of_experience_data:data
            })
        } catch(error){
            console.log(error)
        }
    }
    getCategoryInTheRanksList = async()=>{
        // let API_URL_LOCAL = 'http://localhost:9595/'
        let API_URL = 'https://encuestabienestarmen.pedagogica.edu.co/services/'
        try{
            let response = await fetch(`${API_URL}form/category_in_the_ranks`)
            let data = await response.json()
            this.setState({
                category_in_the_ranks_data:data
            })
        } catch(error){
            console.log(error)
        }
    }

    // Handle fields change props
    handleChangeForm = (e)=>{
        const {name,value} = e.target
        this.setState({
            [name]:value
        })
        console.log("Estado de la Aplicacion", JSON.parse(JSON.stringify(this.state)))
    }
    // Proceed to next step
    nextStep = ()=>  {
        const {step,stepProgess} = this.state
        this.setState({
            step:step + 1,
            stepProgess: stepProgess + 7.2
        })
    }
    // if the user is already in the DB
    endStep = ()=>  {
        this.setState({
            step:15
        })
    }
    // Go back to preview step
    goBackStep = ()=> {
        this.setState({
            step:0
        })
    }
    // Get identity_document from server
    getIdentityDocumentData = async()=>{
        // let API_URL_LOCAL = 'http://localhost:9595/'
        let API_URL = 'https://encuestabienestarmen.pedagogica.edu.co/services/'
        try{
            let response = await fetch(`${API_URL}form/fetch-all`)
            let data = await response.json()
            this.setState({
                identity_document_data:data
            })
        } catch(error){
            console.log(error)
        }
    }
    
    render(){
        const {step} = this.state
        switch(step){
            default :
            return(
                <div>
                    <Progress
                        {...this.state}/>
                    <FirstIndications 
                        nextStep={this.nextStep}
                        {...this.state}/>
                </div>
                
            )
            case 1 :
                return(
                    <div>
                        <Progress
                            {...this.state}/>
                        <Indications 
                            validateID={this.validateID}
                            handleChange={this.handleChangeForm}
                        {...this.state}/>
                    </div>
            )
            case 2 :
                return(
                    <div>
                        <Progress
                            {...this.state}/>
                        <Instructions 
                            nextStep={this.nextStep}/>
                    </div>
            )
            case 3:
                return(
                    <div>
                        <Progress
                            {...this.state}/>
                        <Categories
                            nextStep={this.nextStep}/>
                    </div>
                )
            case 4:
                return(
                    <div>
                        <Progress
                            {...this.state}/>
                        <FormGeneralDetails
                            nextStep={this.nextStep}
                            handleChange={this.handleChangeForm}
                            {...this.state}/>
                    </div>
                )
            case 5:
                return(
                    <div>
                        <Progress
                            {...this.state}/>
                        <FormGeneralDetailsMunicipality
                            createForm={this.createForm}
                            handleChange={this.handleChangeForm}
                            {...this.state}/>
                    </div>
                )
            case 6:
            return(
                <div>
                    <Progress
                        {...this.state}/>
                    <FirstSection
                        createFormSection1={this.createFormSection1}
                        handleChange={this.handleChangeForm}
                        {...this.state}/>
                </div>
            )
            case 7:
                return(
                    <div>
                        <Progress
                            {...this.state}/>
                        <SecondSection
                            createFormSection2={this.createFormSection2}
                            handleChange={this.handleChangeForm}
                            {...this.state}/>
                    </div>
                )
            case 8:
                return(
                    <div>
                        <Progress
                            {...this.state}/>
                        <ThirdSection
                            createFormSection3={this.createFormSection3}
                            handleChange={this.handleChangeForm}
                            {...this.state}/>
                    </div>
                )
            case 9:
                return(
                    <div>
                        <Progress
                            {...this.state}/>
                        <FourthSection
                            createFormSection4={this.createFormSection4}
                            handleChange={this.handleChangeForm}
                            {...this.state}/>
                    </div>
                )
            case 10:
                return(
                    <div>
                        <Progress
                            {...this.state}/>
                        <SubjectiveSection
                            createFormSection5={this.createFormSection5}
                            handleChange={this.handleChangeForm}
                            {...this.state}/>
                    </div>
                )
            case 11:
                return(
                    <div>
                        <Progress
                            {...this.state}/>
                        <FifthSection
                            createFormSection6={this.createFormSection6}
                            handleChange={this.handleChangeForm}
                            {...this.state}/>
                    </div>
                )
            case 12:
                return(
                    <div>
                        <Progress
                            {...this.state}/>
                        <SixthSection
                            createFormSection7={this.createFormSection7}
                            handleChange={this.handleChangeForm}
                            {...this.state}/>
                    </div>
                )
            case 13:
                return(
                    <div>
                        <Progress
                            {...this.state}/>
                        <SeventhSection
                            createFormSection8={this.createFormSection8}
                            handleChange={this.handleChangeForm}
                            {...this.state}/>
                    </div>
                )
            case 14:
                return(
                    <div>
                        <Progress
                            {...this.state}/>
                        <LastSection
                            nextStep={this.nextStep}
                            handleChange={this.handleChangeForm}
                            {...this.state}/>
                    </div>
                )
            case 15:
            return(
                <AlreadySection
                    goBackStep={this.goBackStep}
                    handleChange={this.handleChangeForm}
                    {...this.state}/>
            )
            }
        }
    }
export default Home