import React,{Component} from 'react'
import M from 'materialize-css/dist/js/materialize.min.js'

import './css/sixthSection.css'
let seventhQuestions = [
    '63. Campeonatos deportivos',
    '64. Celebración de fechas y eventos especiales',
    '65. Acceder a descuentos en planes familiares vacacionales',
    '66. Campañas de sentido de pertenencia',
    '67. Talleres de relaciones interpersonales asertivas',
    '68. Programas de comunicación asertiva, estratégica y colaborativa',
    '69. Estrategias de aprendizaje significativo e intercambio de saberes con padres de familia y docentes',
    '70. Reconocimiento público por capacidades individuales y experiencias significativas',
    '71. Búsqueda del mérito y logro a través del reconocimiento de la labor',
    '72. Encuentros de desarrollo humano para promover  interacciones positivas en el sistema educativo, con la participación de padres de familia, compañeros de trabajo y directivas',
    '73. Encuentros de desarrollo humano para promover interacciones positivas en el sistema educativo, con la participación de directivos docentes, docentes y estudiantes',
    '74. ¿Habla un segundo idioma?',
]
class seventhSection extends Component{
    componentDidMount() {
        M.AutoInit();
    }
    // Proceed to next step
    continue = (event)=> {
        event.preventDefault();
        this.props.createFormSection8();
    }
    // Validate Message
    validateForm7= ()=>{
        if(!this.props.question63){
            alert('Selecciona una opcion para la pregunta numero 63')
        } else if(!this.props.question64){
            alert('Selecciona una opcion para la pregunta numero 64')
        } else if(!this.props.question65){
            alert('Selecciona una opcion para la pregunta numero 65')
        } else if(!this.props.question66){
            alert('Selecciona una opcion para la pregunta numero 66')
        }else if(!this.props.question67){
            alert('Selecciona una opcion para la pregunta numero 67')
        }else if(!this.props.question68){
            alert('Selecciona una opcion para la pregunta numero 68')
        }else if(!this.props.question69){
            alert('Selecciona una opcion para la pregunta numero 69')
        }else if(!this.props.question70){
            alert('Selecciona una opcion para la pregunta numero 70')
        }else if(!this.props.question71){
            alert('Selecciona una opcion para la pregunta numero 71')
        }else if(!this.props.question72){
            alert('Selecciona una opcion para la pregunta numero 72')
        }else if(!this.props.question73){
            alert('Selecciona una opcion para la pregunta numero 73')
        }else if(!this.props.question74){
            alert('Selecciona una opcion para la pregunta numero 74')
        }
    }
    
render(){
    // Extra game question 63
    let extra_game_question63
    if(this.props.question63 === '3'){
        extra_game_question63 = 
            <div className='enabledSelect'>
                <div className='input-field col s12'>
                    <span className='regularSpan'><b>Indique cuales le interesan, puede seleccionar varios deportes</b></span>
                </div>
                <div className='input-field col s3'>
                    {/* Sport 1 */}
                    <label htmlFor='situation1'>Baloncesto</label>
                    <p className='radioAligner'>
                        <label className='radioSeparator radioAlignerYes'>
                            <input value='1' name="extra_game_question63_1" type="radio" onChange={this.props.handleChange}/>
                            <span></span>
                        </label>                                        
                    </p>
                    {/* Sport 1 */}
                </div>
                <div className='input-field col s1'>
                </div>
                <div className='input-field col s3'>
                    {/* Sport 2 */}
                    <label htmlFor='situation1'>Fútbol</label>
                    <p className='radioAligner'>
                        <label className='radioSeparator radioAlignerYes'>
                            <input value='1' name="extra_game_question63_2" type="radio" onChange={this.props.handleChange}/>
                            <span></span>
                        </label>                                        
                    </p>
                    {/* Sport 2 */}
                </div>
                <div className='input-field col s1'>
                </div>
                <div className='input-field col s3'>
                    {/* Sport 3 */}
                    <label htmlFor='situation1'>Fútbol Sala</label>
                    <p className='radioAligner'>
                        <label className='radioSeparator radioAlignerYes'>
                            <input value='1' name="extra_game_question63_3" type="radio" onChange={this.props.handleChange}/>
                            <span></span>
                        </label>                                        
                    </p>
                    {/* Sport 3 */}
                </div>
                <div className='input-field col s1'>
                </div>
                <div className='input-field col s3'>
                    {/* Sport 4 */}
                    <label htmlFor='situation1'>Voleibol</label>
                    <p className='radioAligner'>
                        <label className='radioSeparator radioAlignerYes'>
                            <input value='1' name="extra_game_question63_4" type="radio" onChange={this.props.handleChange}/>
                            <span></span>
                        </label>                                        
                    </p>
                    {/* Sport 4 */}
                </div>
                <div className='input-field col s1'>
                </div>
                <div className='input-field col s3'>
                    {/* Sport 5 */}
                    <label htmlFor='situation1'>Ciclismo</label>
                    <p className='radioAligner'>
                        <label className='radioSeparator radioAlignerYes'>
                            <input value='1' name="extra_game_question63_5" type="radio" onChange={this.props.handleChange}/>
                            <span></span>
                        </label>                                        
                    </p>
                    {/* Sport 5 */}
                </div>
                <div className='input-field col s1'>
                </div>
                <div className='input-field col s3'>
                    {/* Sport 6 */}
                    <label htmlFor='situation1'>Boxeo</label>
                    <p className='radioAligner'>
                        <label className='radioSeparator radioAlignerYes'>
                            <input value='1' name="extra_game_question63_6" type="radio" onChange={this.props.handleChange}/>
                            <span></span>
                        </label>                                        
                    </p>
                    {/* Sport 6 */}
                </div>
                <div className='input-field col s1'>
                </div>
                <div className='input-field col s3'>
                    {/* Sport 7 */}
                    <label htmlFor='situation1'>Básquetbol</label>
                    <p className='radioAligner'>
                        <label className='radioSeparator radioAlignerYes'>
                            <input value='1' name="extra_game_question63_7" type="radio" onChange={this.props.handleChange}/>
                            <span></span>
                        </label>                                        
                    </p>
                    {/* Sport 7 */}
                </div>
                <div className='input-field col s1'>
                </div>
                <div className='input-field col s3'>
                    {/* Sport 8 */}
                    <label htmlFor='situation1'>Patinaje</label>
                    <p className='radioAligner'>
                        <label className='radioSeparator radioAlignerYes'>
                            <input value='1' name="extra_game_question63_8" type="radio" onChange={this.props.handleChange}/>
                            <span></span>
                        </label>                                        
                    </p>
                    {/* Sport 8 */}
                </div>
                <div className='input-field col s1'>
                </div>
                <div className='input-field col s3'>
                    {/* Sport 9 */}
                    <label htmlFor='situation1'>Ajedrez</label>
                    <p className='radioAligner'>
                        <label className='radioSeparator radioAlignerYes'>
                            <input value='1' name="extra_game_question63_9" type="radio" onChange={this.props.handleChange}/>
                            <span></span>
                        </label>                                        
                    </p>
                    {/* Sport 9 */}
                </div>
                <div className='input-field col s1'>
                </div>
                <div className='input-field col s3'>
                    {/* Sport 10 */}
                    <label htmlFor='situation1'>Natación</label>
                    <p className='radioAligner'>
                        <label className='radioSeparator radioAlignerYes'>
                            <input value='1' name="extra_game_question63_10" type="radio" onChange={this.props.handleChange}/>
                            <span></span>
                        </label>                                        
                    </p>
                    {/* Sport 10 */}
                </div>
                <div className='input-field col s1'>
                </div>
                <div className='input-field col s3'>
                    {/* Sport 11 */}
                    <label htmlFor='situation1'>Tejo</label>
                    <p className='radioAligner'>
                        <label className='radioSeparator radioAlignerYes'>
                            <input value='1' name="extra_game_question63_11" type="radio" onChange={this.props.handleChange}/>
                            <span></span>
                        </label>                                        
                    </p>
                    {/* Sport 11 */}
                </div>
                <div className='input-field col s1'>
                </div>
                <div className='input-field col s3'>
                    {/* Sport 12 */}
                    <label htmlFor='situation1'>Billar</label>
                    <p className='radioAligner'>
                        <label className='radioSeparator radioAlignerYes'>
                            <input value='1' name="extra_game_question63_12" type="radio" onChange={this.props.handleChange}/>
                            <span></span>
                        </label>                                        
                    </p>
                    {/* Sport 12 */}
                </div>
                <div className='input-field col s3'>
                    {/* Sport 13 */}
                    <label htmlFor='situation1'>Tenis de mesa</label>
                    <p className='radioAligner'>
                        <label className='radioSeparator radioAlignerYes'>
                            <input value='1' name="extra_game_question63_13" type="radio" onChange={this.props.handleChange}/>
                            <span></span>
                        </label>                                        
                    </p>
                    {/* Sport 13 */}
                </div>
                <div className='input-field col s1'>
                </div>
                <div className='input-field col s3'>
                    {/* Sport 14 */}
                    <label htmlFor='situation1'>Atletismo</label>
                    <p className='radioAligner'>
                        <label className='radioSeparator radioAlignerYes'>
                            <input value='1' name="extra_game_question63_14" type="radio" onChange={this.props.handleChange}/>
                            <span></span>
                        </label>                                        
                    </p>
                    {/* Sport 14 */}
                </div>
                <div className='input-field col s1'>
                </div>
                <div className='input-field col s3'>
                    {/* Sport 15 */}
                    <label htmlFor='situation1'>Mini-tejo</label>
                    <p className='radioAligner'>
                        <label className='radioSeparator radioAlignerYes'>
                            <input value='1' name="extra_game_question63_15" type="radio" onChange={this.props.handleChange}/>
                            <span></span>
                        </label>                                        
                    </p>
                    {/* Sport 15 */}
                </div>
                <div className='input-field col s12'>
                    {/* Sport 16 */}
                    <input 
                        placeholder='Que otro juego le interesa'
                        className="validate"
                        required
                        maxLength="50"
                        type='text' 
                        name="extra_game_question63_16"
                        onChange={this.props.handleChange}/>
                    {/* Sport 16 */}
                </div>
            </div>
    } else if(this.props.question63 !== '3') {
        extra_game_question63 = 
            <div className='disabledSelect'>
            </div>
    }
    // Extra game question 63
    // Extra game question 74
    let extra_game_question74
    if(this.props.question74 === '1'){
        extra_game_question74 = 
            <div className='enabledSelect'>
                <div className='input-field col s12'>
                    <span className='regularSpan'><b>Cuál:</b></span>
                </div>
                <div className='input-field col s3'>
                    {/* Language 1 */}
                    <label htmlFor='situation1'>Inglés</label>
                    <p className='radioAligner'>
                        <label className='radioSeparator radioAlignerYes'>
                            <input value='1' name="extra_question74_1" type="radio" onChange={this.props.handleChange}/>
                            <span></span>
                        </label>                                        
                    </p>
                    {/* Language 1 */}
                </div>
                <div className='input-field col s1'>
                </div>
                <div className='input-field col s3'>
                    {/* Language 2 */}
                    <label htmlFor='situation1'>Portugués</label>
                    <p className='radioAligner'>
                        <label className='radioSeparator radioAlignerYes'>
                            <input value='1' name="extra_question74_2" type="radio" onChange={this.props.handleChange}/>
                            <span></span>
                        </label>                                        
                    </p>
                    {/* Language 2 */}
                </div>
                <div className='input-field col s1'>
                </div>
                <div className='input-field col s3'>
                    {/* Language 3 */}
                    <label htmlFor='situation1'>Alemán</label>
                    <p className='radioAligner'>
                        <label className='radioSeparator radioAlignerYes'>
                            <input value='1' name="extra_question74_3" type="radio" onChange={this.props.handleChange}/>
                            <span></span>
                        </label>                                        
                    </p>
                    {/* Language 3 */}
                </div>
                <div className='input-field col s1'>
                </div>
                <div className='input-field col s3'>
                    {/* Language 4 */}
                    <label htmlFor='situation1'>Francés</label>
                    <p className='radioAligner'>
                        <label className='radioSeparator radioAlignerYes'>
                            <input value='1' name="extra_question74_4" type="radio" onChange={this.props.handleChange}/>
                            <span></span>
                        </label>                                        
                    </p>
                    {/* Language 4 */}
                </div>
                <div className='input-field col s1'>
                </div>
                <div className='input-field col s2'>
                    {/* Language 5 */}
                    <label htmlFor='situation1'>Italiano</label>
                    <p className='radioAligner'>
                        <label className='radioSeparator radioAlignerYes'>
                            <input value='1' name="extra_question74_5" type="radio" onChange={this.props.handleChange}/>
                            <span></span>
                        </label>                                        
                    </p>
                    {/* Language 5 */}
                </div>
                <div className='input-field col s5'>
                    {/* Language 6 */}
                    <label htmlFor='situation1'>Palenque y/o lengua indígena</label>
                    <p className='radioAligner'>
                        <label className='radioSeparator radioAlignerYes'>
                            <input value='1' name="extra_question74_6" type="radio" onChange={this.props.handleChange}/>
                            <span></span>
                        </label>                                        
                    </p>
                    {/* Language 6 */}
                </div>
                <div className='input-field col s12'>
                    {/* Language 16 */}
                    <input 
                        placeholder='Otro idioma'
                        className="validate"
                        required
                        maxLength="50"
                        type='text' 
                        name="extra_question74_7"
                        onChange={this.props.handleChange}/>
                    {/* Language 16 */}
                </div>
                {/* Level Question 74 */}
                <div className='input-field col s12'>
                    <p>
                        <label>Cuál es el nivel de dominio del segundo idioma:</label>
                    </p>
                    <p className='col s12'>
                        <label className='radioAlignerYes radioSeparator'>
                            <input required value='3' name="extra_question74_level" type="radio" onChange={this.props.handleChange}/>
                            <span className='regularSpan'>Alto</span>
                        </label>                                        
                        <label className='radioAlignerNo radioSeparator'>
                            <input value='2' name="extra_question74_level" type="radio" onChange={this.props.handleChange}/>
                            <span className='regularSpan'>Medio</span>
                        </label>
                        <label className='radioAlignerYes radioSeparator'>
                            <input value='1' name="extra_question74_level" type="radio" onChange={this.props.handleChange}/>
                            <span className='regularSpan'>Bajo</span>
                        </label> 
                    </p>
                </div>
                {/* Level Question 74 */}
            </div>
    } else if(this.props.question74 !== '1') {
        extra_game_question74 = 
            <div className='disabledSelect'>
            </div>
    }
    return (
        <div className='container'>
            <div className='row'>
                <div className='input-field col s12'>
                </div>
                <div className='col s12'>
                    <div className='card'>
                        <div className='sixthSectionBox'>
                            <span className='fifthSectionBoxTitle col s12'>III. Bienestar Interaccionista: <br></br> Deportivas, Recreativas y Vacacionales, y Relaciones Humanas. </span>
                        </div>
                        <div className='card-content'>
                        <h6 className='fGeneralDetailsSubTitle'>A continuación, encontrará una serie de actividades 
                        agrupadas en dos categorías:
                        <br></br><br></br>
                        - Deportivas, Recreativas y Vacacionales
                        <br></br>
                        - Relaciones Humanas.<br></br><br></br> Por favor, indique el grado de gusto 
                        por cada premisa, considerando que: 3 significa que le interesa mucho, 2 que le es indiferente y 1 que le interesa poco.
                        <br></br><br></br> Esto es para conocer cuáles son sus preferencias en el rol social que 
                        desempeña como Docente o Directivo Docente. </h6>
                            <form onSubmit={this.continue}>
                                <div className='row'>
                                    <div className='col s12 separator'>
                                        <span className='fifthLabelQuestion'>Deportivas, Recreativas y Vacacionales</span>
                                    </div>
                                    {/* Section 7.1 */}
                                    <div className='input-field col s12 separator'>
                                        <p>
                                            <label>{seventhQuestions[0]}</label>
                                        </p>
                                        <p className='col s12'>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input required value='3' name="question63" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>3. Me interesa mucho</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='2' name="question63" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>2. Me es indiferente</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='1' name="question63" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>1. Me interesa poco</span>
                                            </label> 
                                        </p>
                                    </div>
                                    {/* extra_game_question63 */}
                                    <div className='input-field col s12'>
                                        {extra_game_question63}
                                    </div>
                                    {/* extra_game_question63 */}
                                    <div className='input-field col s12'>
                                        <p>
                                            <label>{seventhQuestions[1]}</label>
                                        </p>
                                        <p className='col s12'>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input required value='3' name="question64" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>3. Me interesa mucho</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='2' name="question64" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>2. Me es indiferente</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='1' name="question64" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>1. Me interesa poco</span>
                                            </label> 
                                        </p>
                                    </div>
                                    <div className='input-field col s12 separator'>
                                        <p>
                                            <label>{seventhQuestions[2]}</label>
                                        </p>
                                        <p className='col s12'>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input required value='3' name="question65" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>3. Me interesa mucho</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='2' name="question65" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>2. Me es indiferente</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='1' name="question65" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>1. Me interesa poco</span>
                                            </label> 
                                        </p>
                                    </div>
                                    {/* Section 7.1 */}
                                    <div className='col s12 separator'>
                                        <span className='fifthLabelQuestion'>Relaciones Humanas </span>
                                    </div>
                                    <div className='input-field col s12 separator'>
                                        <p>
                                            <label>{seventhQuestions[3]}</label>
                                        </p>
                                        <p className='col s12'>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input required value='3' name="question66" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>3. Me interesa mucho</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='2' name="question66" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>2. Me es indiferente</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='1' name="question66" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>1. Me interesa poco</span>
                                            </label> 
                                        </p>
                                    </div>
                                    {/* Section 7.2 */}
                                    <div className='input-field col s12 separator'>
                                        <p>
                                            <label>{seventhQuestions[4]}</label>
                                        </p>
                                        <p className='col s12'>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input required value='3' name="question67" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>3. Me interesa mucho</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='2' name="question67" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>2. Me es indiferente</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='1' name="question67" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>1. Me interesa poco</span>
                                            </label> 
                                        </p>
                                    </div>
                                    <div className='input-field col s12 separator'>
                                        <p>
                                            <label>{seventhQuestions[5]}</label>
                                        </p>
                                        <p className='col s12'>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input required value='3' name="question68" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>3. Me interesa mucho</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='2' name="question68" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>2. Me es indiferente</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='1' name="question68" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>1. Me interesa poco</span>
                                            </label> 
                                        </p>
                                    </div>
                                    <div className='input-field col s12 separator'>
                                        <p>
                                            <label>{seventhQuestions[6]}</label>
                                        </p>
                                        <p className='col s12'>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input required value='3' name="question69" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>3. Me interesa mucho</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='2' name="question69" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>2. Me es indiferente</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='1' name="question69" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>1. Me interesa poco</span>
                                            </label> 
                                        </p>
                                    </div>
                                    <div className='input-field col s12 separator'>
                                        <p>
                                            <label>{seventhQuestions[7]}</label>
                                        </p>
                                        <p className='col s12'>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input required value='3' name="question70" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>3. Me interesa mucho</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='2' name="question70" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>2. Me es indiferente</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='1' name="question70" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>1. Me interesa poco</span>
                                            </label> 
                                        </p>
                                    </div>
                                    <div className='input-field col s12 separator'>
                                        <p>
                                            <label>{seventhQuestions[8]}</label>
                                        </p>
                                        <p className='col s12'>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input required value='3' name="question71" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>3. Me interesa mucho</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='2' name="question71" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>2. Me es indiferente</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='1' name="question71" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>1. Me interesa poco</span>
                                            </label> 
                                        </p>
                                    </div>
                                    <div className='input-field col s12 separator'>
                                        <p>
                                            <label>{seventhQuestions[9]}</label>
                                        </p>
                                        <p className='col s12'>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input required value='3' name="question72" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>3. Me interesa mucho</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='2' name="question72" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>2. Me es indiferente</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='1' name="question72" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>1. Me interesa poco</span>
                                            </label> 
                                        </p>
                                    </div>
                                    <div className='input-field col s12 separator'>
                                        <p>
                                            <label>{seventhQuestions[10]}</label>
                                        </p>
                                        <p className='col s12'>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input required value='3' name="question73" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>3. Me interesa mucho</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='2' name="question73" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>2. Me es indiferente</span>
                                            </label>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input value='1' name="question73" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>1. Me interesa poco</span>
                                            </label> 
                                        </p>
                                    </div>
                                    <div className='input-field col s12 separator'>
                                        <p>
                                            <label>{seventhQuestions[11]}</label>
                                        </p>
                                        <p className='col s12'>
                                            <label className='radioAlignerYes radioSeparator'>
                                                <input required value='1' name="question74" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>SI</span>
                                            </label>                                        
                                            <label className='radioAlignerNo radioSeparator'>
                                                <input value='0' name="question74" type="radio" onChange={this.props.handleChange}/>
                                                <span className='regularSpan'>NO</span>
                                            </label>
                                        </p>
                                    </div>
                                    {/* extra_game_question63 */}
                                    <div className='input-field col s12'>
                                        {extra_game_question74}
                                    </div>
                                    {/* extra_game_question63 */}
                                    {/* Section 7.2 */}
                                </div>
                                <button onClick={this.validateForm7} className="btn waves-effect waves-light bg-btn" type="submit" name="action">
                                    Enviar
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        )
    }
}
export default seventhSection