import React from 'react'
import Badge from './badge'
import Navbar from './navbar'
// import Footer from './Footer'
// import './styles/layout.css'
function Layout(props) {
        return(
            <div className='layout'>
                <Navbar/>
                <Badge/>
                {props.children}
                {/* <Footer/> */}
            </div>
        )
    }
export default Layout
